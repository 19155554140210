import React, { useState, useRef, useEffect } from "react";
import { useChat } from '../../../context/contextChat';
import { postChatBackup } from '../../../api/api-post';
import { isCyberSecurityRelated } from "./chatFilter";
import ChatIcon from "../Buttons/Chat-icon/button";
import ChatLoader from "../Loaders/Loader-7/Loader-7";


import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './ChatBot.css';

export const ChatBot = () => {
  const { 
    userId,
    chatMessages,
    prompt, setPrompt,
    loading,
    typingMessage,
    messagesEndRef,
    handleChat,
  } = useChat();
  
  const setChatBackup = async () => {
    if (!userId) {
      console.error('User ID is not available.');
      return;
    }
    if (!chatMessages || chatMessages.length === 0) {
      console.error('No chat messages to save.');
      return;
    }
    try {
      const response = await postChatBackup(userId, chatMessages);
      console.log('ChatBackup Response:', response);
    } catch (error) {
      console.error('Error saving chat backup:', error);
    }
  };

  const formatMessage = (text) => {
    // Convert double asterisks to bold
    const parts = text.split(/\*\*(.*?)\*\*/g);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  };

  const sendChat = async (prompt) => {
    const response = await isCyberSecurityRelated(prompt);
    
    if (response === true) {
      handleChat(); // Process the chat for valid cybersecurity input
    } else if (response === false) {
      console.log("Sorry, this chatbot only handles cybersecurity-related topics.");
      alert("Sorry, this chatbot only handles cybersecurity-related topics.");
    } else if (response === null) {
      console.log("Please provide a valid input.");
      alert("Please provide a valid input.");
    }
  };

  function adjustHeight(textarea) {
    textarea.style.height = '43px'; // Reset height to minimum
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Adjust height dynamically but not beyond maxHeight
  }

  useEffect(() => {
    const modalElement = document.getElementById('staticBackdropChatBot');
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    modalElement.addEventListener('shown.bs.modal', scrollToBottom);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', scrollToBottom);
    };
  }, []);

  const textareaRef = useRef(null);
  // Adjust height whenever the component mounts or `prompt` changes
  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [prompt]);
  
  return (
    <>
      <ChatIcon />
      <div className="modal fade" id="staticBackdropChatBot" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content bg-dark text-white rounded-4 shadow">
            <div className="modal-header" style={{ borderBottom: '1px solid #444444' }}>
              <h5 className="modal-title" id="staticBackdropLabel">
                <span><i className="bi bi-chat-right-dots-fill"/></span><span className="px-3">SAVE-GPT</span>
              </h5>
              <button type="button" className="btn-close btn-close-white p-3" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => setChatBackup()}></button>
            </div>
            <div className="modal-body sidebar-scroll" style={{ height: '60vh', overflowY: 'auto' }}>
              {chatMessages ? (
                chatMessages.map((message, index) => (
                  <div key={index} className={message.type === 'user' ? 'text-end' : 'text-start mb-3'}>
                    <div className={`message ${message.type === 'user' ? 'user-message' : 'bot-message'}`}>
                      <p style={{ wordWrap: 'break-word' }}>{formatMessage(message.text)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No messages to display.</p>
              )}
              {loading && (<p><ChatLoader /> Loading...</p>)}
              {typingMessage && (
                <div className="text-start mb-3">
                  <div className="message bot-message">
                    <p style={{ wordWrap: 'break-word' }}>{formatMessage(typingMessage)}</p>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
              <p className="col-9">
                <a href="https://api.whatsapp.com/send?phone=919831318312" className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-decoration-underline" 
                style={{ fontSize: '13px', lineHeight: '1.5' }}>
                  For escalations regarding security issues, please contact the L1 officer directly for swift assistance.
                </a>
              </p>
            </div>
            <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
              <textarea
                ref={textareaRef}
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                  adjustHeight(e.target);
                }}
                className="form-control bg-dark text-white border-1 rounded-3 sidebar-scroll"
                style={{
                  border: '1px solid gray',
                  maxHeight: '100px', // Maximum height
                  height: '46px', // Minimum height
                  paddingRight: '50px',
                  resize: 'none', // Disable manual resizing
                  overflowY: 'auto', // Allow scroll when max height is reached
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent default Enter behavior
                    sendChat(prompt);
                    // handleChat();
                  }
                }}
              ></textarea>
              <button
                type="button"
                className="bi bi-send-fill position-absolute bottom-0 end-0 translate-middle-y mx-4 px-2 bg-transparent border-0"
                style={{ fontSize: '20px', color: 'gray' }}
                onClick={() => {
                  sendChat(prompt);
                  // handleChat();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBot;



const formatMessage2 = (text) => {
  // Convert double asterisks to bold and single asterisks to bullet points
  const parts = text.split(/(\*\*.*?\*\*|\*.*?\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove the asterisks and make bold
    } else if (part.startsWith('*') && part.endsWith('*')) {
      return <li key={index}>{part.slice(1, -1)}</li>; // Remove the asterisks for bullet points
    }
    return part; // Return other text as is
  });
};

const formatMessage3 = (text) => {
  const parts = text.split(/(\*\*\*.*?\*\*\*|\*\*.*?\*\*|\*.*?\*)/g);
  return (
    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
      {parts.map((part, index) => {
        if (part.startsWith('***') && part.endsWith('***')) {
          return <li key={index}><strong>{part.slice(3, -3)}</strong></li>; // Bold bullet point
        } else if (part.startsWith('**') && part.endsWith('**')) {
          return <strong key={index}>{part.slice(2, -2)}</strong>; // Bold text
        } else if (part.startsWith('*') && part.endsWith('*')) {
          return <li key={index}>{part.slice(1, -1)}</li>; // Bullet points
        }
        return part; // Normal text
      })}
    </ul>
  );
};