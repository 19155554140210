import React, { useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import SecurityToolsData from '../config/securityToolsItems';
// import { isDisabled } from "@testing-library/user-event/dist/utils";

const SaveConnect =({title})=>{
  const { upgradePlan } = useAuth();
  const { viewHeight, toggleUpgradeCard } = useDesign(); 
  const toggleCard =()=>{
    if(!upgradePlan){
      toggleUpgradeCard();
    }
  }

  return(
    <>
      <div className="h-100 save-connect">
        <div className='p-1 pb-2'><h4>{title}</h4></div>
        <div className={`d-flex justify-content-left`}
          style={{height: viewHeight}} onClick={toggleCard}>
          <div className='p-1 col-12'>
            <div className={`mt-1 scrollbar-all-primary
              ${!(upgradePlan)?'disable':''}`} 
              style={{height: viewHeight, paddingRight: 10}}>
              <ConnectionBtns />
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default SaveConnect;


function ConnectionBtns(){
  const { upgradePlan } = useAuth();
  const [selectedCard, setSelectedCard] = useState('');  

  return(
    <div className="w-100">
      <div className="row">
        {SecurityToolsData.map((button, index) => (
          <div className="col-auto text-center" key={index}>
            <button
              className={`card shadow-sm m-2 rounded-4 ${!upgradePlan?'disable':''}`}
              type="button" style={{ height: 200, width: 200 }}>
                <div className="d-flex flex-column justify-content-between">
                  <div style={{width: 'auto', height: 130}}>
                    <img className="p-1 w-100"
                      src={button.src} alt={button.alt} />
                  </div>
                  <p className="text-center">{button.title}</p> 
                </div>
            </button>          
          </div>
        ))}
      </div>
    </div>
  )
}