export const processData = (data) => {
  // Group data by scan date and count suspicious and harmless values
  const groupedData = data.reduce((acc, item) => {
    const scanDate = new Date(item.scan_time).toLocaleDateString('en-US');
    if (!acc[scanDate]) {
      acc[scanDate] = { date: scanDate, suspicious: 0, harmless: 0 };
    }
    if (item.suspicious !== null) {
      acc[scanDate].suspicious += 1; // Count suspicious entries
    }
    if (item.harmless !== null) {
      acc[scanDate].harmless += 1; // Count harmless entries
    }
    return acc;
  }, {});

  // Convert grouped data to an array
  return Object.values(groupedData);
};

export const processDataByPeriod = (data, period) => {
  const now = new Date();
  let startDate, endDate;
  endDate = new Date(now);

  switch (period) {
    case 'Last 7 days':
      startDate = getDateDaysAgo(7);
      break;
    case 'Last 28 days':
      startDate = getDateDaysAgo(28);
      break;
    case 'Last 90 days':
      startDate = getDateDaysAgo(90);
      break;
    case 'Last 365 days':
      startDate = getDateDaysAgo(365);
      break;
    case 'Lifetime':
      return processData(data); // Return all data grouped by scan_date
    default:
      return processData(data); // Handle unexpected cases
  }

  return processData(
    data.filter(item => {
      const itemDate = new Date(item.scan_time); // Correct field
      return itemDate >= startDate && itemDate <= endDate;
    })
  );
};

const getDateDaysAgo = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
};

const getStartAndEndOfMonth = (year, month) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  return { startDate, endDate };
};
const getMonthIndex = (monthName) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return monthNames.indexOf(monthName);
};