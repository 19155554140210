import apiConfig from './api-config';
const api_base_url = apiConfig.api_base_url;
const api_key = apiConfig.api_key;

// Post Methods
export const postUrl = async (userId, url) => {
	try {
		if (!userId || !url) {
			throw new Error('User ID and URL are required');
		}

		const token = sessionStorage.getItem('authToken');
		if (!token) {
			throw new Error('Authentication token is missing');
		}
		
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postUrl`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ url })
		});

		if (!response.ok) {
			const errorMessage = `Failed to post URL. Status: ${response.status} ${response.statusText}`;
			console.error(errorMessage);
			throw new Error(errorMessage);
		}
		
		const logs = await response.json();
		// console.log('User Logs:', logs);
		return logs;
	} catch (error) {
		console.error('Error posting user URL:', error.message);
		throw error;
	}
};

export const postIp = async (userId, ip) => {
	// console.log('IP:', ip);
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postIp`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ ip }) // Include the URL in the request body
		});

		if (!response.ok) {
			throw new Error('Failed to fetch user IP');
		}

		const logs = await response.json();
		console.log('User Logs:', logs);
		return logs;
	} catch (error) {
		console.error('Error posting user IP:', error);
		throw error;
	}
};

export const postRemoteIp = async (userId, ip, host, user, password) => {
  try {
    // Input validation
    if (!userId || !ip || !host || !user || !password) {
      throw new Error('All parameters (userId, ip, host, user, password) are required.');
    }

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authorization token is missing. Please log in again.');
    }

    const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postRemoteIp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, ip, host, user, password }),
    });

    if (!response.ok) {
      const errorResponse = await response.text(); // Get error response for debugging
      throw new Error(`Failed to fetch user IP: ${errorResponse}`);
    }

    const logs = await response.json();
    console.log('User Logs:', logs);
    return logs;
  } catch (error) {
    console.error('Error posting user IP:', error.message);
    throw error;
  }
};

export const postGCPKeys = async (userId, accessKey, secretKey) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postGCPKeys`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}` // Include the Bearer token in the header
			},
			body: JSON.stringify({ accessKey, secretKey }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.error || 'Failed to Scan GCP Keys');
		}

		return response.json();
	} catch (error) {
		console.error('Error during GCP scan:', error);
		throw error;
	}
};
export const postAWSKeys = async (userId, accessKey, secretKey) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postAWSKeys`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}` // Include the Bearer token in the header
			},
			body: JSON.stringify({ accessKey, secretKey }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.error || 'Failed to Scan AWS Keys');
		}

		return response.json();
	} catch (error) {
		console.error('Error during AWS scan:', error);
		throw error;
	}
};
export const postAzureKeys = async (userId, accessKey, secretKey) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postAzureKeys`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}` // Include the Bearer token in the header
			},
			body: JSON.stringify({ accessKey, secretKey }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.error || 'Failed to Scan Azure Keys');
		}

		return response.json();
	} catch (error) {
		console.error('Error during Azure scan:', error);
		throw error;
	}
};

export const postMalUrl = async (userId, url) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postMalUrl`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ url })
		});
		
		if (!response.ok) {
			const errorResponse = await response.text();  // Read the error message
			console.error('Error response:', errorResponse);
			throw new Error(`Failed to fetch user URL: ${errorResponse}`);
		}    
		return response.json();
	} catch (error) {
		console.error('Error posting user URL:', error);
		throw error;
	}
};

export const postMalFile = async (userId, file) => {
  try {
    const token = sessionStorage.getItem('authToken'); // Retrieve auth token
    if (!token) {
      throw new Error('Authorization token is missing. Please log in again.');
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append('file', file);

    // Send POST request
    const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postMalFile`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // Add Authorization token
      },
      body: formData, // Attach FormData with the file
    });

    // Check if the response is not OK
    if (!response.ok) {
      const errorText = await response.text(); // Get the error message
      console.error('Error response:', errorText);
      throw new Error(`Failed to upload file: ${response.status} - ${errorText}`);
    }

    // Parse and return the response JSON
    return await response.json();
  } catch (error) {
    console.error('Error posting file:', error.message || error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const chatBot = async (prompt) => {
  try {
    const response = await fetch(`https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyCvnY_8Bgb1cLiapYJN8wo0v_ZQwAG1JbI`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contents: [{
          parts: [{
            text: prompt // Use the provided prompt here
          }]
        }]
      }),
    });

    // Check if the response is ok (status 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse and return the JSON response
    return await response.json();
  } catch (error) {
    console.error('Error fetching user URL details:', error);
    throw error;
  }
};
export const postChatBackup = async (userId, message) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/post/${userId}/postChatBackup`, {
      method: 'POST', // Changed to POST for saving data
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(message),
    });

    if (!response.ok) {
      throw new Error(`Failed to backup chat: ${response.statusText}`);
    }
    return response.json(); // Returning parsed JSON response
  } catch (error) {
    console.error('Chat Backup Error:', error); // Proper 
    throw error; 
  }
};