import apiConfig from './api-config';
const api_base_url = apiConfig.api_base_url;
const api_key = apiConfig.api_key;
// Get Methods for Urls
export const getUrlAssets = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getUrlAssets`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});
	
		return response.json();
	} catch (error) {
		console.error('Error fetching user url details:', error);
		throw error;
	}
};
export const getUrlVulnerabilities = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getUrlVulnerabilities`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		
		const data = await response.json();    
		return Array.isArray(data) ? data : [];
	} catch (error) {
		console.error('Error fetching URL vulnerabilities:', error);
		return []; // Return an empty array on error
	}
};
export const getUrlEpss = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getUrlEpss`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		
		const data = await response.json();    
		return Array.isArray(data) ? data : [];
	} catch (error) {
		console.error('Error fetching URL vulnerabilities:', error);
		return []; // Return an empty array on error
	}
};

// Get Methods for IPs
export const getIpAssets = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getIpAssets`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});
	
		return response.json();
	} catch (error) {
		console.error('Error fetching user url details:', error);
		throw error;
	}
};
export const getIpVulnerabilities = async (userId) => {
	try{
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getIpVulnerabilities`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		return response.json();
	}catch(error){
		throw(error);
	}
};
export const getIpEpss = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getIpEpss`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		
		const data = await response.json();    
		return Array.isArray(data) ? data : [];
	} catch (error) {
		console.error('Error fetching URL vulnerabilities:', error);
		return []; // Return an empty array on error
	}
};
export const getIpServices = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getIpServices`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		
		const data = await response.json();    
		return Array.isArray(data) ? data : [];
	} catch (error) {
		console.error('Error fetching URL vulnerabilities:', error);
		return []; // Return an empty array on error
	}
};

// Get Methods for Clouds
export const getCloudAssets = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getCloudAssets`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});
	
		return response.json();
	} catch (error) {
		console.error('Error fetching user url details:', error);
		throw error;
	}
};

export const getCloudAWSAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {throw new Error('No token found');}

    const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getAWSMisconfigData`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token expired') {
        alert('Your session has expired. Please log in again.');
      } else {
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }
    }
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}
export const getCloudGCPAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {throw new Error('No token found');}

    const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getGCPMisconfigData`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token expired') {
        alert('Your session has expired. Please log in again.');
      } else {
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }
    }
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}
export const getCloudAzureAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {throw new Error('No token found');}

    const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getAzureMisconfigData`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token expired') {
        alert('Your session has expired. Please log in again.');
      } else {
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }
    }
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}

// Get Methods for Malware
export const getMalwareAssets = async (userId) => {
	try {
		const token = sessionStorage.getItem('authToken');
		const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getMalwareAssets`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});
	
		return response.json();
	} catch (error) {
		console.error('Error fetching user url details:', error);
		throw error;
	}
};

export const getChatBackup = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/get/${userId}/getChatBackup`, {  
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to retrieve chat backup: ${response.statusText}`);
    }
    // console.log(response);
    return response.json();

  } catch (error) {
    console.error('Chat Backup Error:', error);
    throw error;
  }
};

export const getWifiInfo = async () => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/wifi`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      }
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Wi-Fi information:', error);
    throw error;
  }
};