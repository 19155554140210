import React from "react";

const onLoadFile = ({viewHeight, activeTabResult, handleClickResult, setActiveTabResult, 
  activeTab, handleClick, setLoading, setShowData, setUrlInput, urlInput, userId, showData}) => {
  const tabStyle = {
    width: 160,
    height: 50,
    cursor: 'pointer',
    borderRadius: 0,
    border: 0,
    outline: 0,
    fontWeight: 600,
  };
  const tabColor = '#344266';
  const props = {viewHeight, activeTabResult, handleClickResult, setActiveTabResult, 
    activeTab, handleClick, setLoading, setShowData, setUrlInput, urlInput, userId, showData};
  
  return (
    <div className="w-100 d-flex justify-content-left px-5">        
      <div className="col-10">
        <div><HeaderView {...props}/></div>
        <div className="px-4">
          <div className="h3 my-3">Scan Results:</div>
          <div className="d-flex justify-content-left align-items-center">
            <button className="py-1 px-4 btn"
              style={activeTabResult === 'Detection'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
              onClick={() => handleClickResult('Detection')}>Detection
            </button>
            <button className="py-1 px-4 btn"
              style={activeTabResult === 'Details'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
              onClick={() => handleClickResult('Details')}>Details
            </button>
          </div>
          <div className="d-flex justify-content-center flex-column">                
            <div className="scrollbar-all-primary" style={{ height: `calc(${viewHeight} - 240px`}}>
              {activeTabResult === 'Detection' && <DetectionView {...props} />}
              {activeTabResult === 'Details' && <DetailsView {...props} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default onLoadFile;

function HeaderView({showData}) {
  const fileSize = showData.meta.file_info.size;
  const stats = showData.data.attributes.stats;
  const date_of_scan_unix = showData.data.attributes.date;
  const date_of_scan = convertToRelativeTime(date_of_scan_unix);
  
  console.log(showData);
  return(
    <>
      <div className="d-flex flex-row align-items-center py-3 px-4 rounded-4 text-white" style={{background: '#344266'}}>
        <div className="col">
          <span className="pe-2"><i className="bi bi-file-earmark"/></span>
          <div>Filesize: {fileSize}</div>
          <div>Date of scan: {date_of_scan}</div>
        </div>
        <div className="d-flex flex-column">
          <div>Malicious: {stats.malicious}</div>          
          <div>Suspicious: {stats.suspicious}</div>
          <div>Undetected: {stats.undetected}</div>
          <div>Harmless: {stats.harmless}</div>
        </div>
      </div>
    </>
  );
};

function DetectionView({showData}){  
  const scan_results = showData.data.attributes.results;
  return(
    <>     
      <div className="">
        <table border="0" cellPadding="10" cellSpacing="0">          
          <tr>
            <th>Engine Name</th>
            <th>Method</th>
            <th>Category</th>
            <th><span className="px-1"/>Result</th>
          </tr>     
          {Object.keys(scan_results).map((key, index) => (
            <tr key={index}>
              <td>{scan_results[key].engine_name}</td>
              <td>{scan_results[key].method}</td>
              <td>{scan_results[key].category}</td>
              <td>
                <span className="px-1">
                  <i className={`bi bi-${scan_results[key].result === 'clean' ? 'check-circle-fill' : 'question-lg'}`}
                    style={{color: scan_results[key].result === 'clean' ? '#00a73d': '#373b3e',}}
                  />
                </span>
                {scan_results[key].result}
              </td>              
            </tr>
          ))}          
        </table>
      </div>
    </>
  )
}

function DetailsView({showData}){
  const url = showData.data.attributes.url;
  const status_code = showData.data.attributes.last_http_response_code;
  // const content_type = showData.attributes.last_http_response_headers[Content-Type];
  const date_of_last_scan = showData.data.attributes.last_analysis_date;
  return(
    <>
      <div className="d-flex flex-column">
        <div className="col"><span><i className="bi bi-link-45deg"></i></span> {url}</div>
        <div className="col">
          <div>Status<br/>{status_code}</div>
          <div>|</div>
          {/* <div>Content type<br/>{content_type}</div> */}
          <div>|</div>
          <div>Data last scan<br/>{date_of_last_scan}</div>          
        </div>
      </div>
    </>
  );
};

const convertToRelativeTime = (timestamp) => {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
  const timeDifference = currentTime - timestamp; // Time difference in seconds

  // Convert time difference into readable format
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (timeDifference < secondsInMinute) {
    return `${timeDifference} seconds ago`;
  } else if (timeDifference < secondsInHour) {
    const minutes = Math.floor(timeDifference / secondsInMinute);
    return `${minutes} minutes ago`;
  } else if (timeDifference < secondsInDay) {
    const hours = Math.floor(timeDifference / secondsInHour);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(timeDifference / secondsInDay);
    return `${days} days ago`;
  }
};