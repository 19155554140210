import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { useAuth } from './contextAuth';
import {
  getUrlAssets, getUrlVulnerabilities, getUrlEpss,
  getIpAssets, getIpVulnerabilities, getIpEpss, getIpServices,
  getCloudAssets, getCloudAWSAssets, getCloudGCPAssets, getCloudAzureAssets,
  getMalwareAssets,
  getChatBackup,
 } from '../api/api-get';
import DefaultProfileImg from '../modules/asset/img/default-profile.avif';
import { generateProfilePic } from '../utils/generateProfilePic';
import { getImageUrl } from '../api/users';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { setUser, setUserId, isTokenExpired, signout } = useAuth();  
  const [chatMessages, setChatMessages] = useState([]);

  const [webLoading, setWebLoading] = useState(false);
  const [urlScanLoading, setUrlScanLoading] = useState(false);        // Used to display the loading animation inside the WebScanCard
  const [urlDashboardView, setUrlDashboardView] = useState(false);    // Used to toggle the WebDashboard with WebScanCard
  const [urlAssets, setUrlAssets] = useState(null);                   // Used to load the assets table inside the WebDashboard
  const [urlHeader, setUrlHeader] = useState(false);                  // Used to display the header inside the WebDashboard
  const [urlIndex, setUrlIndex] = useState(0);                        // Used to display the current asset index inside the WebDashboard
  const [urlVulTableAll, setUrlVulTableAll] = useState([]);           // Used to load the vulnerability table inside the WebDashboard & Reports
  const [urlVulTableToggle, setUrlVulTableToggle] = useState(false);  // Used to toggle the vulnerability table insed the reports
  const [urlEpssAll, setUrlEpssAll] = useState([]);                   // Used to load the epss table inside the WebDashboard
  const [urlEpssTable, setUrlEpssTable] = useState(null);             // Used to display the filtered epss table inside the WebDashboard  
  
  const [networkLoading, setNetworkLoading] = useState(null);
  const [ipScanLoading, setIpScanLoading] = useState(false);          // Used to display the loading animation inside the IpScanCard
  const [ipDashboardView, setIpDashboardView] = useState(false);      // Used to toggle the IpDashboard with IpScanCard  
  const [ipAssets, setIpAssets] = useState(null);                     // Used to load the assets table inside the IpDashboard
  const [ipHeader, setIpHeader] = useState(false);                    // Used to display the header inside the IpDashboard
  const [ipIndex, setIpIndex] = useState(0);                          // Used to display the current asset index inside the IpDashboard
  const [ipVulTableAll, setIpVulTableAll] = useState([]);             // Used to load the vulnerability table inside the IpDashboard & Reports
  const [ipVulTableToggle, setIpVulTableToggle] = useState(false);    // Used to toggle the vulnerability table insed the reports
  const [ipServicesAll, setIpServicesAll] = useState([]);             // Used to load the open port services list inside the IpDashboard
  const [ipEpssAll, setIpEpssAll] = useState([]);                     // Used to load the epss table inside the IpDashboard
  const [ipEpssTable, setIpEpssTable] = useState(null);               // Used to display the filtered epss table inside the IpDashboard
  
  const [cloudLoading, setCloudLoading] = useState(false);
  const [cloudScanLoading, setCloudScanLoading] = useState(false);				        // Used to display the loading animation inside the CloudScanCard
  const [cloudDashboardView, setCloudDashboardView] = useState(false);		        // Used to toggle the CloudDashboard with CloudScanCard
  const [cloudVulTableAll, setCloudVulTableAll] = useState([]);				            // Used to load the vulnerability table inside the CloudDashboard & Reports

  // AWS State Variables
  const [cloudAWSAssets, setCloudAWSAssets] = useState(null);                 		  // Used to load the assets table inside the CloudDashboard
  const [cloudAWSHeader, setCloudAWSHeader] = useState(false);                	  // Used to display the header inside the CloudDashboard
  const [cloudAWSIndex, setCloudAWSIndex] = useState(0);                      	  // Used to display the current asset index inside the CloudDashboard						
  const [cloudAWSMisconfigAll, setCloudAWSMisconfigAll] = useState([]);						// Used to load the vulnerability table inside the CloudDashboard & Reports
  const [cloudAWSMisconfigToggle, setCloudAWSMisconfigToggle] = useState(false);	// Used to toggle the vulnerability table inside the reports

  // GCP State Variables
  const [cloudGCPAssets, setCloudGCPAssets] = useState(null);                 		    // Used to load the assets table inside the CloudDashboard for GCP
  const [cloudGCPHeader, setCloudGCPHeader] = useState(false);                	    // Used to display the header inside the CloudDashboard for GCP
  const [cloudGCPIndex, setCloudGCPIndex] = useState(0);                      	    // Used to display the current asset index inside the CloudDashboard for GCP						
  const [cloudGCPMisconfigAll, setCloudGCPMisconfigAll] = useState([]);						  // Used to load the vulnerability table inside the CloudDashboard & Reports for GCP
  const [cloudGCPMisconfigToggle, setCloudGCPMisconfigToggle] = useState(false);	  // Used to toggle the vulnerability table inside the reports for GCP

  // Azure State Variables
  const [cloudAzureAssets, setCloudAzureAssets] = useState(null);                 	    // Used to load the assets table inside the CloudDashboard for Azure
  const [cloudAzureHeader, setCloudAzureHeader] = useState(false);                	  // Used to display the header inside the CloudDashboard for Azure
  const [cloudAzureIndex, setCloudAzureIndex] = useState(0);                      	  // Used to display the current asset index inside the CloudDashboard for Azure						
  const [cloudAzureMisconfigAll, setCloudAzureMisconfigAll] = useState([]);					  // Used to load the vulnerability table inside the CloudDashboard & Reports for Azure
  const [cloudAzureMisconfigToggle, setCloudAzureMisconfigToggle] = useState(false);	// Used to toggle the vulnerability table inside the reports for Azure  

  const [malwareLoading, setMalwareLoading] = useState(false);
  const [malwareScanLoading, setMalwareScanLoading] = useState(false);				// Used to display the loading animation inside the MalwareScanCard
  const [malwareDashboardView, setMalwareDashboardView] = useState(false);		// Used to toggle the MalwareDashboard with MalwareScanCard
  const [malwareAssets, setMalwareAssets] = useState([]);                 		// Used to load the assets table inside the MalwareDashboard
  const [malwareHeader, setMalwareHeader] = useState(false);                	// Used to display the header inside the MalwareDashboard
  const [malwareIndex, setMalwareIndex] = useState(0);                      	// Used to display the current asset index inside the MalwareDashboard
  
  const [totalSaveScore, setTotalSaveScore] = useState('0');
  const [upgradePlan, setUpgradePlan] = useState(0);
  const [isSidebarExtended, setSidebarExtended] = useState(true);
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [showUpgradeCard, setShowUpgradeCard] = useState(false);
  const [showEpssCard, setShowEpssCard] = useState(false);  
  const [profilePicture, setProfilePicture] = useState(DefaultProfileImg);

  useEffect(() => {
    const fetchData = async () => {
      try {
        loadUserData();
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };
    
    fetchData();
  }, []);
  
  const loadUserData = async () => {    
    const token = sessionStorage.getItem('authToken');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = sessionStorage.getItem('userId');
    const upgrade_plan = sessionStorage.getItem('plan');
  
    if (!token || !user || !userId || !upgrade_plan) {
      signout();
      signoutdata();
      return;
    }
    
    setUser(user);
    setUserId(userId);
    setUpgradePlan(upgrade_plan);

    if(!isTokenExpired(token)) {
      await loadUrlAssets(userId);
      await loadUrlData(userId);
      await loadIpAssets(userId);
      await loadIpData(userId);

      await loadCloudAssets(userId, "AWS");   // Load AWS assets
      await loadCloudAssets(userId, "GCP");   // Load GCP assets
      await loadCloudAssets(userId, "Azure"); // Load Azure assets

      await loadMalwareAssets(userId);
      await loadChatMessages(userId);
      await loadProfileCard(user);
    }else{
      signout();
      signoutdata();
    }
  };

  async function loadChatMessages(userId) {
    try {
      const fetchChat = await getChatBackup(userId);
      setChatMessages(fetchChat);
    } catch (error) {
      // console.error('Error loading chat messages:', error);
      setChatMessages([]);
    }
  };
  const loadProfileCard = async(user) => {
    try {
      if (user?.profile_image_path) {
        const imageUrl = await getImageUrl(user.profile_image_path);
        setProfilePicture(imageUrl);
      } else if (user?.company) {  // Check if company exists before generating a profile picture
        setProfilePicture(generateProfilePic(user?.company)); // Use user.company directly
      }
    } catch (error) {
      // console.error('Error loading profile card:', error);
      throw error;
    }
  }
  const loadUrlAssets = async (userId) => {
    try {
      const urlAssets_data = await getUrlAssets(userId);
      setUrlAssets(urlAssets_data ? urlAssets_data: null);
    } catch (error) {
      // console.error('Error during initial data fetch:', error);
      throw error;
    }
  };
  const loadUrlData = async (userId) => {
    try {
      const vulnerabilities = await getUrlVulnerabilities(userId);
      const epss = await getUrlEpss(userId);
      setUrlVulTableAll(vulnerabilities);
      setUrlEpssAll(epss);
    } catch (error) {
      // console.error('Error fetching URL vulnerabilities:', error);
      throw error;
    }
  };
  const loadIpAssets = async (userId) => {
    try {
      const ipAssets_data = await getIpAssets(userId);
      setIpAssets(ipAssets_data ? ipAssets_data : null);
    } catch (error) {
      // console.error('Error during initial data fetch:', error);
      throw error;
    }
  };
  const loadIpData = async (userId) => {
    try {
      const services = await getIpServices(userId);
      const vulnerabilities = await getIpVulnerabilities(userId);
      const epss = await getIpEpss(userId);      
      setIpVulTableAll(vulnerabilities);
      setIpEpssAll(epss);
      setIpServicesAll(services);
    } catch (error) {
      // console.error('Error fetching IP vulnerabilities:', error);
      throw error;
    }
  };
  const loadCloudAssets = async (userId, provider) => {
    try {
      let cloudAssetsData;
  
      switch (provider) {
        case "AWS":
          cloudAssetsData = await getCloudAWSAssets(userId);
          setCloudAWSAssets(cloudAssetsData || []);
          break;
        case "GCP":
          cloudAssetsData = await getCloudGCPAssets(userId);
          setCloudGCPAssets(cloudAssetsData || []);
          break;
        case "Azure":
          cloudAssetsData = await getCloudAzureAssets(userId);
          setCloudAzureAssets(cloudAssetsData || []);
          break;
        default:
          throw new Error("Invalid provider specified.");
      }
    } catch (error) {
      console.error(`Error loading ${provider} assets:`, error);
      throw error;
    }
  };

  const loadMalwareAssets = async (userId) => {
    try {
      const malwareAssets_data = await getMalwareAssets(userId);
      setMalwareAssets(malwareAssets_data || null);
    } catch (error) {
      // console.error('Error during initial data fetch:', error);
      throw error;
    }
  };

  const signoutdata = () => {
    try{
      setWebLoading(false);
      setNetworkLoading(false);
      setCloudLoading(false);

      setUrlScanLoading(false);
      setUrlDashboardView(false);
      setUrlAssets(null);
      setUrlHeader(false);
      setUrlIndex(0);
      setUrlVulTableAll([]);
      setUrlVulTableToggle(false);
      setUrlEpssAll([]);
      setUrlEpssTable(null);
      
      setIpScanLoading(false);
      setIpDashboardView(false);
      setIpAssets(null);
      setIpHeader(false);
      setIpIndex(0);
      setIpVulTableAll([]);
      setIpVulTableToggle(false);
      setIpEpssAll([]);
      setIpEpssTable(null);

      setCloudScanLoading(false);
      setCloudDashboardView(false);
      setCloudVulTableAll([]);

      setCloudAWSAssets(null);
      setCloudAWSHeader(false);
      setCloudAWSIndex(0);
      setCloudAWSMisconfigAll([]);

      setCloudGCPAssets(null);
      setCloudGCPHeader(false);
      setCloudGCPIndex(0);
      setCloudGCPMisconfigAll([]);

      setCloudAzureAssets(null);
      setCloudAzureHeader(false);
      setCloudAzureIndex(0);
      setCloudAzureMisconfigAll([]);

      setTotalSaveScore('0');
      setSidebarExtended(true);
      setShowProfileCard(false);
      setShowUpgradeCard(false);
      setShowEpssCard(false);
      console.log('Signed out all data successfully.');
    }catch(error){
      console.error('Error signing out the data:', error);
    }    
  }  

  return (
    <UserContext.Provider
      value={{
        chatMessages, setChatMessages,
				
        webLoading, setWebLoading,
				urlScanLoading, setUrlScanLoading,
				urlDashboardView, setUrlDashboardView,
        urlAssets, setUrlAssets,
				urlHeader, setUrlHeader,
        urlIndex, setUrlIndex,
        urlVulTableAll, setUrlVulTableAll,
        urlVulTableToggle, setUrlVulTableToggle,
        urlEpssAll, setUrlEpssAll,
        urlEpssTable, setUrlEpssTable, 
        
        networkLoading, setNetworkLoading,
				ipScanLoading, setIpScanLoading,
				ipDashboardView, setIpDashboardView,
        ipAssets, setIpAssets,
				ipHeader, setIpHeader,
        ipIndex, setIpIndex,
        ipVulTableAll, setIpVulTableAll,
				ipVulTableToggle, setIpVulTableToggle,
				ipServicesAll, setIpServicesAll,
				ipEpssAll, setIpEpssAll,
				ipEpssTable, setIpEpssTable,

        cloudLoading, setCloudLoading,
				cloudScanLoading, setCloudScanLoading,
				cloudDashboardView, setCloudDashboardView,
        cloudVulTableAll, setCloudVulTableAll,
        cloudAWSAssets, setCloudAWSAssets,
        cloudAWSHeader, setCloudAWSHeader,
        cloudAWSIndex, setCloudAWSIndex,
        cloudAWSMisconfigAll, setCloudAWSMisconfigAll,
        cloudAWSMisconfigToggle, setCloudAWSMisconfigToggle,
        cloudGCPAssets, setCloudGCPAssets,
        cloudGCPHeader, setCloudGCPHeader,
        cloudGCPIndex, setCloudGCPIndex,
        cloudGCPMisconfigAll, setCloudGCPMisconfigAll,
        cloudGCPMisconfigToggle, setCloudGCPMisconfigToggle,
        cloudAzureAssets, setCloudAzureAssets,
        cloudAzureHeader, setCloudAzureHeader,
        cloudAzureIndex, setCloudAzureIndex,
        cloudAzureMisconfigAll, setCloudAzureMisconfigAll,
        cloudAzureMisconfigToggle, setCloudAzureMisconfigToggle,
				
        malwareLoading, setMalwareLoading,
        malwareScanLoading, setMalwareScanLoading,
        malwareDashboardView, setMalwareDashboardView,
        malwareAssets, setMalwareAssets,
        malwareHeader, setMalwareHeader,
        malwareIndex, setMalwareIndex,

        totalSaveScore, setTotalSaveScore,
        upgradePlan, setUpgradePlan,
        isSidebarExtended, setSidebarExtended,
        showProfileCard, setShowProfileCard,
        showUpgradeCard, setShowUpgradeCard,
        showEpssCard, setShowEpssCard,
        profilePicture, setProfilePicture,
        loadUserData
      }}>{children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};