import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useNavigate, Link } from 'react-router-dom';

export default function MFA() {
  const { getVerify, setLoadingAuth } = useAuth();
  const { loadUserData } = useUser();
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(Array(6).fill('')); // Array for 6-digit code
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!timerActive) return;  
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          setTimerActive(false);
          return 0; // Ensure it reaches 0 and doesn't go negative
        }
        return prevSeconds - 1;
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, [timerActive]);

  useEffect(() => {
    if (!timerActive && seconds === 0) {
      navigate('/');
    }
  }, [timerActive, seconds, navigate]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const newCode = [...code];
    
    // Update the code array with the new value
    newCode[index] = value;
    setCode(newCode);
  
    // If the value is not empty and the current index is less than the last index, move focus to the next input field
    if (value !== '' && index < code.length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (code[index] === '') {
        if (index > 0) {
          const prevInput = document.getElementById(`code-input-${index - 1}`);
          if (prevInput) {
            prevInput.focus();
          }
        }
      }
    }
  };
  
  const enteredCode = code.join('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getVerify(enteredCode);
      if (response.error) {
        console.error('Error verifying 2FA:', response.error);
      }
      await loadUserData();
      navigate('/');
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      setError(error.message);
    }finally{
      setLoadingAuth(false);
    }
  };
  
  // add loadUrlAssets
  return (
    <div className='mfa-wrapper'>
      <div className='position-absolute top-50 start-50 translate-middle text-center'>
        <div className='bg-white py-3 pb-3 rounded-5' 
          style={{ paddingLeft: 40, paddingRight: 40}}>
          <div className='p-1'>
            <i className='bi bi-fingerprint' style={{ fontSize: '80px' }} />
          </div>
          <div className='pb-5 d-flex justify-content-center align-items-center' style={{ height: 60 }}>
            {timerActive && <div style={{ fontSize: '40px' }}>{seconds}</div>}
            {!timerActive && <div style={{ fontSize: '20px' }}>Timer expired!</div>}
          </div>
          <div className="code-inputs">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`code-input-${index}`}
                className="code-input rounded-3 mx-1"
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}              
              />
            ))}            
          </div>
          <div className='py-3'>6-digit Code is sent to your email</div>
          <div className='text-center mt-2 mb-4 sign-wrapper'>
            <button className="btn border-0 w-100 rounded-3" onClick={handleSubmit}>Submit</button>
            <div style={{ fontSize: '14px', marginTop: 10 }}>
              Go to Sign in
              <Link to="/" style={{ color: 'red' }}>
                <b> Sign In</b>
              </Link>
            </div>          
          </div>
        </div>        
      </div>
    </div>
  );
}