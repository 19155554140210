import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../../context/contextAuth';
import { fetchUserLogs } from '../../../api/users';
import './style.css';

const BellIcon = () => {
  const [showNotify, setShowNotify] = useState(false);
  const dropdownRef = useRef(null);
  const [notificationCount, setNotificationCount] = useState('+9');

  const handleClick = () => {
    setShowNotify(!showNotify);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowNotify(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown">
      <div>
        <button className="btn btn-secondary bg-transparent border-0 position-relative"
          type="button" onClick={handleClick}>
          <i className="bi bi-bell-fill bellIcon" style={{ fontSize: '1.5rem' }} />
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style={{ marginTop: 9, marginLeft: -9 }}>
            {notificationCount}
            <span className="visually-hidden">unread messages</span>
          </span>
        </button>
      </div>
      {showNotify && <ShowNotifications />}
    </div>
  );
};
export default BellIcon;

const ShowNotifications = () => {
  const { userId } = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const logs = await fetchUserLogs(userId);
        setNotifications(logs);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (userId) {
      fetchNotifications();
    }
  }, [userId]);

  return (
    <>
      <div className="position-absolute bg-light rounded-5 shadow-lg overflow-y-auto"
        style={{
          width: 350,
          maxHeight: 500,
          right: -150,
          top: 50,
          transition: 'all 0.3s ease-in-out',
          border: 'solid 1px rgba(48, 33, 88, 0.3)',
          zIndex: 1000,
        }}>
        {/* Notifications Header */}
        <div 
        className='d-flex justify-content-center align-items-center text-white rounded-top-5 py-2'
        style={{background: '#302154', height: 60 }}>
          <h5 className='m-0'>Notifications</h5>
        </div>

        {/* Notifications List */}
        <div className={`py-2 scrollbar-notify w-100 ${!notifications.length ? 'pb-4': 'ps-3 pe-3'}`}>
          {!notifications.length ? (
            <div className="text-center py-5">
              <i className="bi bi-envelope-open fs-3 text-secondary"></i>
              <p className="m-0 text-secondary">No notifications yet</p>
            </div>
          ) : (
            notifications.map((notification, index) => (
              <div key={index} className="rounded-4 mb-2 shadow-sm bg-white p-3 border">
                <div style={{ fontSize: '14px', color: '#444' }}>
                  <div className="fw-bold">{notification.title || 'Notification'}</div>
                  <div className="text-muted" style={{ fontSize: '12px' }}>
                    {new Date(notification.timestamp).toLocaleString()}
                  </div>
                  <div className="pt-1">{notification.message}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};