import React from "react";

const onLoadUrl = ({
  viewHeight, activeTabResult, 
  handleClickResult, handleClick, showData
}) => {
  const tabStyle = {
    width: 160,
    height: 50,
    cursor: 'pointer',
    borderRadius: 0,
    border: 0,
    outline: 0,
    fontWeight: 600,
  };
  const tabColor = '#344266';
  const props = {
    viewHeight, activeTabResult, 
    handleClickResult, handleClick, showData
  };

  return (
    <div className="w-100 d-flex justify-content-left px-5">        
      <div className="col-10">
        <div><HeaderView {...props}/></div>
        <div className="px-4">
          <div className="h3 my-3">Scan Results:</div>
          <div className="d-flex justify-content-left align-items-center">
            <button className="py-1 px-4 btn"
              style={activeTabResult === 'Detection'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
              onClick={() => handleClickResult('Detection')}>Detection
            </button>
            <button className="py-1 px-4 btn"
              style={activeTabResult === 'Details'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
              onClick={() => handleClickResult('Details')}>Details
            </button>
          </div>
          <div className="d-flex justify-content-center flex-column">                
            <div className="scrollbar-all-primary" style={{ height: `calc(${viewHeight} - 240px`}}>
              {activeTabResult === 'Detection' && <DetectionView {...props} />}
              {activeTabResult === 'Details' && <DetailsView {...props} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default onLoadUrl;

function HeaderView({showData}) {
  const url = showData.data.attributes.url;
  const status_code = showData.data.attributes.last_http_response_code;
  const content_type = showData.data.attributes.last_http_response_headers['Content-Type'];
  const date_of_last_scan_unix = showData.data.attributes.last_analysis_date;
  const date_of_last_scan = convertToRelativeTime(date_of_last_scan_unix);

  console.log(showData);
  return(
    <>
      <div className="d-flex flex-row align-items-center py-3 px-4 rounded-4 text-white" style={{background: '#344266'}}>
        <div className="col">
          <span><i className="bi bi-link-45deg"/></span> {url}</div>
        <div className="d-flex flex-row">
          <div>Status<br/>
            <b>
              <i className={`bi bi-${status_code === 200 ? 'check-circle-fill' : 'x-circle-fill'} me-1`} 
                style={{color: status_code === 200?'#00a73d': '#fff'}}
              />{status_code}</b>
            </div>
          <div className='mx-3' style={{borderLeft: 'solid 2px #fff', height: 50, width: 2}}/>
          <div>Content type<br/><b>{content_type}</b></div>
          <div className='mx-3' style={{borderLeft: 'solid 2px #fff', height: 50, width: 2}}/>
          <div>Data last scan<br/><b>{date_of_last_scan}</b></div>
        </div>
      </div>
    </>
  );
};

function DetectionView({showData}){  
  const scan_results = showData.data.attributes.last_analysis_results;
  const total_votes = showData.data.attributes.total_votes;
  return(
    <>     
      <div className="">
        <table border="0" cellPadding="10" cellSpacing="0">          
          <tr>
            <th>Engine Name</th>
            <th>Method</th>
            <th>Category</th>
            <th><span className="px-1"/>Result</th>
          </tr>     
          {Object.keys(scan_results).map((key, index) => (
            <tr key={index}>
              <td>{scan_results[key].engine_name}</td>
              <td>{scan_results[key].method}</td>
              <td>{scan_results[key].category}</td>
              <td>
                <span className="px-1">
                  <i
                    className={`bi bi-${
                      scan_results[key].result === 'clean'
                        ? 'check-circle-fill'
                        : scan_results[key].result === 'phishing'
                        ? 'exclamation-circle'
                        : 'question-lg'
                    }`}
                    style={{
                      color:
                        scan_results[key].result === 'clean'
                          ? '#00a73d'
                          : scan_results[key].result === 'phishing'
                          ? 'red'
                          : '#373b3e',
                    }}
                  />
                </span>
                {scan_results[key].result}
              </td>           
            </tr>
          ))}          
        </table>
      </div>
    </>
  )
}

function DetailsView({ showData }) {
  const url = showData.data.attributes.url;
  const categories = showData.data.attributes.categories;
  const firstSubmission = showData.data.attributes.first_submission_date;
  const lastSubmission = showData.data.attributes.last_submission_date;
  const lastAnalysis = showData.data.attributes.last_analysis_date;
  const bodyLength = showData.data.attributes.last_http_response_content_length;
  const bodySHA = showData.data.attributes.last_http_response_content_sha256;
  const header = showData.data.attributes.last_http_response_headers;
  const totalVotes = showData.data.attributes.total_votes;

  return (
    <>
      <div className="d-flex flex-column">
        {/* Categories */}
        <div className="col">
          <h4>Categories</h4>
          <ul>
            {Object.entries(categories).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>

        {/* History */}
        <div className="col">
          <h4>History</h4>
          <ul>
            <li>
              <strong>First Submission:</strong> {firstSubmission}
            </li>
            <li>
              <strong>Last Submission:</strong> {lastSubmission}
            </li>
            <li>
              <strong>Last Analysis:</strong> {lastAnalysis}
            </li>
          </ul>
        </div>

        {/* HTTP Response */}
        <div className="col">
          <h4>HTTP Response</h4>
          <ul>
            <li>
              <strong>Final URL:</strong> {url}
            </li>
            <li>
              <strong>Body Length:</strong> {bodyLength} KB
            </li>
            <li>
              <strong>Body SHA-256:</strong> {bodySHA}
            </li>
          </ul>
        </div>

        {/* HTTP Response Headers */}
        <div className="col">
          <h4>HTTP Response Headers</h4>
          <ul>
            {Object.entries(header).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>

        {/* Total Votes */}
        <div className="col">
          <h4>Total Votes</h4>
          <ul>
            <li>
              <strong>Harmless:</strong> {totalVotes.harmless}
            </li>
            <li>
              <strong>Malicious:</strong> {totalVotes.malicious}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

const convertToRelativeTime = (timestamp) => {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
  const timeDifference = currentTime - timestamp; // Time difference in seconds

  // Convert time difference into readable format
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (timeDifference < secondsInMinute) {
    return `${timeDifference} seconds ago`;
  } else if (timeDifference < secondsInHour) {
    const minutes = Math.floor(timeDifference / secondsInMinute);
    return `${minutes} minutes ago`;
  } else if (timeDifference < secondsInDay) {
    const hours = Math.floor(timeDifference / secondsInHour);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(timeDifference / secondsInDay);
    return `${days} days ago`;
  }
};