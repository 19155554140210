import React, { createContext, useContext, useState, useEffect } from 'react';
import { signin, signup, verify2FACode } from '../api/users';
import { jwtDecode } from '../utils/jwtDecode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [upgradePlan, setUpgradePlan] = useState(0);
  const [token, setToken] = useState();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [error, setError] = useState(null);
  
  const getSignin = async (credentials) => {
    setLoadingAuth(true);
    setError(null);
    try {
      const response = await signin(credentials);
      if (response.token) {
        sessionStorage.setItem('authToken2FA', response.token);
      }
      return response;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoadingAuth(false);
    }
  };
  
  const getVerify = async (enteredCode) => {
    setLoadingAuth(true);
    setError(null);
    try {
      const authToken2FA = sessionStorage.getItem('authToken2FA');
      console.log('authToken2FA: ', authToken2FA);
      if (!authToken2FA) {
        throw new Error('No 2FA token found');
      }

      const decodedToken = jwtDecode(authToken2FA);
      const userId = decodedToken.id || decodedToken.payload?.id; // Use the correct field

      if (!userId) {
        throw new Error('User ID not found in token');
      }
      
      const response = await verify2FACode(userId, enteredCode);
      
      if (response.error) {
        throw new Error(response.error);
      }

      sessionStorage.removeItem('authToken2FA');
      sessionStorage.setItem('authToken', response.token);

      // Properly store the user object
      sessionStorage.setItem('user', JSON.stringify(response.user));
      sessionStorage.setItem('userId', response.user?.id);
      sessionStorage.setItem('plan', response.user?.upgrade_plan);
      
      setUser(response.user);
      setUserId(response.user?.id);
      setUpgradePlan(response.user?.upgrade_plan);
      return response;
    } catch (error) {
      console.log('An error occurred during signin:', error.message);
      setError(error.message);
    } finally {
      setLoadingAuth(false);
    }
  };

  const getSignup = async (data) => {
    setLoadingAuth(true);
    setError(null);
    try {
      const response = await signup(data);
      if (response.token) {
        sessionStorage.setItem('authToken2FA', response.token);
      }
      return response;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoadingAuth(false);
    }
  };
  
  const signout = (e) => {
    try{
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('plan');
      setUser(null);
      setUserId(null);
      setUpgradePlan(0);
      setToken(null);
      setError(null);
      if(e) console.log('Signed out successfully.');
    }catch(error){
      console.error('Error signing out:', error);
    }  
  };
  
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp && decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; 
    }
  };
  
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const userId = sessionStorage.getItem('userId');
    const plan = sessionStorage.getItem('plan');    
  
    if (!isTokenExpired(token)) {
      setUser(userData);
      setUserId(userId);
      setUpgradePlan(plan);
    } else {
      signout();
    }
  }, []);

  const contextValue = {
    user, setUser,
    userId, setUserId,
    token, setToken,
    loadingAuth, setLoadingAuth, 
    upgradePlan, setUpgradePlan,
    getVerify,
    getSignin, 
    getSignup, 
    signout,
    isTokenExpired,
    error,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};