import React from "react";
import Loader from "./Loaders/Loader-1/Loader-1"; 

const NotFound404 =()=>{
  return(
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        <Loader/>
        {/* <h1>404 - Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p> */}
      </div>      
    </>
  )
}

export default NotFound404;


