
import React, { useState, useEffect } from "react";
import { useUser } from '../../../context/contextUser';
import { useDesign } from '../../../context/contextDesign';
import { useChat } from '../../../context/contextChat';

const EpssCard = () => {
  const { toggleEpssCard } = useDesign();
  return (
    <div className="profile-card position-absolute top-50 start-50 translate-middle 
        z-3 d-flex py-3 pb-4 border rounded-4 bg-white shadow-lg"
      style={{ height: 600, width: 1000 }}>
      <div className="card-body d-flex flex-column">
        <CVETable />
      </div>
      <div className="position-absolute top-0 end-0 p-4">
        <i className="bi bi-x-lg" 
          style={{ fontSize: 20, cursor: 'pointer' }} onClick={toggleEpssCard} />
      </div>
    </div>
  );
};
export default EpssCard;

const CVETable = () => {
  const { activeNav } = useDesign();
  const { urlEpssTable, ipEpssTable, cloudEpssTable } = useUser();
  const { setPrompt, setMount } = useChat();
  const { toggleEpssCard } = useDesign();
  const [vulnerabilities, setVulnerabilities] = useState([]);

  useEffect(() => {
    setVulnerabilities(activeNav === 1 ? urlEpssTable : activeNav === 2 ? ipEpssTable : cloudEpssTable);
  }, [activeNav, urlEpssTable, ipEpssTable, cloudEpssTable]);

  const handleOnClickCVE = async (data) => {
    const formattedData = `
      Give me step-by-step remediation action plan for the below CVE Details:
      CVE ID: ${data.cveId},
      EPSS: ${Number(data.epss).toFixed(4)},
      Severity: ${data.severity},
      Exploitability: ${Number(data.exploitability).toFixed(2)},
      Impact: ${Number(data.impact).toFixed(2)},
      Confidentiality: ${data.confidentiality},
      Integrity: ${data.integrity},
      Availability: ${data.availability},
      Base Score: ${Number(data.baseScore).toFixed(1)},
      Remediation: ${data.remediation}
    `;
    toggleEpssCard();
    setPrompt(String(formattedData));
    setMount(true);
  };

  return (
    <div className="table-responsive px-4 scrollbar-all-primary mt-5">
      {vulnerabilities && vulnerabilities.length > 0 ? (
        <table className="table table-striped table-hover">
          <thead className="table-light">
            <tr style={{ 
              textAlign: 'center', 
              fontSize: '14px', 
              fontWeight: 'bold',
              position: 'sticky',  
              top: 0, zIndex: 2,            
              }}>
              <th style={{ width: '15%' }}>CVE ID</th>
              <th style={{ width: '10%' }}>EPSS</th>
              <th style={{ width: '10%' }}>Severity</th>
              <th style={{ width: '13%' }}>Exploitability</th>
              <th style={{ width: '8%' }}>Impact</th>
              <th style={{ width: '13%' }}>Confidentiality</th>
              <th style={{ width: '10%' }}>Integrity</th>
              <th style={{ width: '10%' }}>Availability</th>
              <th style={{ width: '10%' }}>Base Score</th>
            </tr>
          </thead>
          <tbody>
            {vulnerabilities
              .sort((a, b) => Number(b.epss) - Number(a.epss)) // Sorting by EPSS in descending order
              .map((cve, index) => (
                <tr key={index} style={{ fontSize: '12px', textAlign: 'center' }}>
                  <td className="text-start">
                    <span className="badge cve-badge text-bg-secondary py-2"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropChatBot"
                      style={{ 
                        cursor: 'pointer',
                        fontSize: 12,
                        width: '100%',
                      }}
                      onClick={async () => {
                        const data = {
                          cveId: cve.cveId,
                          epss: cve.epss,
                          severity: cve.baseSeverity,
                          exploitability: cve.exploitability,
                          impact: cve.impact,
                          confidentiality: cve.confidentiality,
                          integrity: cve.integrity,
                          availability: cve.availability,
                          baseScore: cve.baseScore,
                          remediation: cve.remediation
                        };
                        setMount(true);
                        handleOnClickCVE(data);
                      }}>{cve.cveId}</span>
                  </td>
                  <td>{Number(cve.epss).toFixed(6)}</td>
                  <td>{cve.baseSeverity}</td>
                  <td>{Number(cve.exploitability).toFixed(2)}</td>
                  <td>{Number(cve.impact).toFixed(2)}</td>
                  <td>{cve.confidentiality}</td>
                  <td>{cve.integrity}</td>
                  <td>{cve.availability}</td>
                  <td>{Number(cve.baseScore).toFixed(1)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center py-3">No data available</div>
      )}
    </div>
  );
};