import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import menuItems from '../config/menuItems';
import Test from './test';
import Navbar from './navbar/x-navbar';
import SideNavbar from './navbar/side-nav/index';
import ProfileCard from './components/Cards/c-profile-card';
import UpgradeCard from './components/Cards/c-upgrade-card';
import CyberIntel from './components/Cards/Cyber-Threat-Intel';
import PolicyPolicy from './o-x-PrivacyPolicy';
import ChatBot from './components/ChatBot/ChatBot';
import TermsConditions from './o-x-TermsConditions';
import SignIn from './--SignIn';
import SignUp from './--SignUp';
import MFA from './--MFA';
import Dashboard from './s-item-0-Dashboard';
import NotFound404 from './components/NotFound404';
import 'react-circular-progressbar/dist/styles.css';
import Loader from './components/Loaders/Loader-1/Loader-1';

export default function Main() {
  const { user } = useAuth();
  const { FadingComponent, mainBodyHeight, mainBodyWidth, activeNav } = useDesign();
  const { loading, showUpgradeCard, showProfileCard } = useUser();
  
  return (
    <>
      {!user ? (
        <Routes>
          <Route path="/signup" element={<SignUp  />} />
          <Route path="/mfa" element={<MFA  />} />
          <Route path="/" element={<SignIn  />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="w-100 h-100">
              {showUpgradeCard && <UpgradeCard  />}
              {showProfileCard && <ProfileCard  />}
              {/* {showEpssCard && <EpssCard  />} */}
              <Navbar  />
              <SideNavbar  />
              <div className='main-body px-4 py-4 m-2
                position-absolute bottom-0 end-0 
                rounded-5'
                style={{
                  backgroundColor: '#f3f3f3',
                  height: mainBodyHeight,
                  width: mainBodyWidth,
                  transition: 'all 0.2s',
                }}>
                <FadingComponent>
                  <div>
                    <Routes>
                      {menuItems.map((item) => (
                        <Route
                          key={activeNav}
                          path={item.path}
                          element={<item.component title={item.title} />}                     
                        />
                      ))}
                      <Route path="/" element={<Dashboard  />} />
                      <Route path="/Privacy-Policy" element={<PolicyPolicy  />} />
                      <Route path="/Terms-Conditions" element={<TermsConditions  />} />
                      <Route path="*" element={<NotFound404 />} />
                    </Routes>
                  </div>
                </FadingComponent>
              </div>
              <div className="position-absolute bottom-0 end-0"
                style={{ padding: 70, paddingBottom: 130 }}>
                <ChatBot />
              </div>
              <div
                className="position-absolute bottom-0 end-0"
                style={{ padding: 70, paddingBottom: 60 }}>
                <div className="d-flex justify-content-center align-items-center rounded-3"
                  data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#ffffff" className="bi bi-exclamation-circle-fill z-2 position-absolute" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                  </svg>
                  <div className="pulsatingDotBig position-absolute z-1"></div>
                </div>
              </div>
              <div
                className="offcanvas offcanvas-end"
                data-bs-backdrop="static"
                tabIndex={-1}
                id="staticBackdrop"
                aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                  <div className="h1 offcanvas-title" id="staticBackdropLabel">
                    Cyber Intel
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                  <CyberIntel />
                </div>
              </div>              
            </div>
          )}
        </>
      )}
    </>
  );
}
