import React from "react";
import './Loader-8.css';

const Loader_8 = () => {
  return (
    <div className="loader_8">
      <div className="loader mx-auto"></div>
    </div>
  );
};

export default Loader_8;