import React, { useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useNavigate, Link } from 'react-router-dom';
import Loader3 from './components/Loaders/Loader-3/Loader-3';
import LogoImg from './asset/img/logo/Save-Logo-Blue-2.webp';

export default function SignUpPage() {
  const { loadingAuth, getSignup } = useAuth();
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const [firstName, ...lastNameParts] = fullName.trim().split(' ');
    const lastName = lastNameParts.join(' ');

    const data = {
      username,
      password,
      first_name: firstName,
      last_name: lastName,
      company,
      designation,
      email,
      phone_number: phoneNumber,
    };

    try {
      const response = await getSignup(data);
      if (response.error) {
        setError(response.error); // Display backend error message
      } else {
        navigate('/');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="sign-wrapper">
        {loadingAuth ? (
          <Loader3 />
        ) : (
          <div className="position-absolute top-50 start-50 translate-middle">
            <div className="scrollbar-all-primary bg-white py-5 pb-5 rounded-5 px-4 px-lg-5 signup-form">
              <div className="logo pb-4 mb-2">
                <img
                  className="mx-auto d-block"
                  src={LogoImg}
                  alt="Logo"
                  style={{ height: 50 }}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Full Name */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control rounded-3"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Full Name</label>
                    </div>
                  </div>

                  {/* Username */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control rounded-3"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Username</label>
                    </div>
                  </div>
                  
                  {/* Company */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control rounded-3"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Company</label>
                    </div>
                  </div>

                  {/* Designation */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control rounded-3"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Designation</label>
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="email"
                        className="form-control rounded-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Email</label>
                    </div>
                  </div>

                  {/* Phone Number */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control rounded-3"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Phone Number</label>
                    </div>
                  </div>                  

                  {/* Password */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        className="form-control rounded-3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Password</label>
                    </div>
                  </div>

                  {/* Confirm Password */}
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        className="form-control rounded-3"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder=" "
                        required
                      />
                      <label className="text-secondary">Confirm Password</label>
                    </div>
                  </div>
                </div>

                {/* Remember Me */}
                <div className="form-check mb-2 d-flex justify-content-center align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="rememberMe"
                    checked={isRememberMeChecked}
                    onChange={(e) => setIsRememberMeChecked(e.target.checked)}
                    style={{ marginRight: 10 }}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember Me
                  </label>
                </div>

                {/* Submit Button */}
                <div className='text-center'>
                  {error && <div className="error text-danger text-center text-wrap mb-2 mx-auto">{error}</div>}
                  <button className="btn border-0 w-100 rounded-3" type="submit">Submit</button>
                  <div className="mt-3 bottom-text">
                    Already have an account?
                    <Link to="/signin">
                      &nbsp;<b className="bottom-text-highlight">Sign In</b>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
