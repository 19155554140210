import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth.js';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import { 
  ResponsiveContainer, CartesianGrid, LineChart, 
  XAxis, Line, Tooltip, Legend 
} from 'recharts';
import { postUrl } from '../api/api-post.js';
import { getUrlAssets, getUrlVulnerabilities, getUrlEpss } from '../api/api-get.js';
import { processDataByPeriod } from '../utils/processDate.js';
import RiskExposurelist from './components/Cards/RiskExposureList-Url.jsx';
import Vulnerabilities from './components/Cards/c-item-0-vulnerabilities.jsx';
import CriticalVul from './components/Cards/c-item-1-critical-vulnerability.jsx';
import SaveScore from './components/Cards/c-save-score.jsx';
import Solutions from './components/Cards/c-solutions.jsx';
import Vid5 from './asset/vid/network/vid-5.webm';
import WebLoader from './components/Loaders/Loader-5/Loader-5';
import WebLoader2 from './components/Loaders/Loader-8/Loader-8';
import EpssCard from './components/Cards/c-epss-card.jsx';

const WebDashboard = ({title}) => {
  const { viewHeight } = useDesign();
  const {
    webLoading,
    urlAssets, urlDashboardView, setUrlDashboardView,
    urlScanLoading, urlHeader, setUrlHeader,
    urlIndex, setUrlIndex
  } = useUser();
  
  useEffect(() => {    
    if(!urlScanLoading){
      if (urlAssets?.length > 0) {
        if(!webLoading) setUrlHeader(true);
        else setUrlHeader(false);
        setUrlDashboardView(true);
      } else if (urlAssets?.length === 0) {
        setUrlHeader(false);
        setUrlDashboardView(false);
      } else{
        setUrlHeader(false);
        setUrlDashboardView(false);
      }
    }
  }, [urlAssets, urlIndex]);
  
  return (
    <div className="h-100 web-dashboard">
      {webLoading ? (
        <WebLoader />
      ) : (
        <>
          {/* {error && <div>{error}</div>} */}
          {urlHeader ? (
            <div className='pb-3'>
              <span className='fs-6 fw-semibold'>
                Dashboard
                <i className="bi bi-caret-right-fill px-1" style={{fontSize: 12}}></i>
                {title} |              
              </span>
              <span className='p-1 ps-2 pe-2 ms-2 bg-secondary rounded-2 text-white'
                style={{ fontSize: '12px' }}>
                URL: {urlAssets[urlIndex]?.url_scan}
              </span>
            </div>
          ) : (
            <div className='pb-3'>
              <span className='fs-6 fw-semibold'>
                Dashboard
                <i className="bi bi-caret-right-fill px-1" style={{fontSize: 12}}></i>
                {title}             
              </span>
            </div>
          )}
          {!urlDashboardView ? (
            <div className='p-1 col-12 scrollbar-all-primary'>
              <div className="mt-1" style={{ height: viewHeight, paddingRight: 10 }}>
                <WebScanCard />
              </div>
            </div>
          ) : (
            <>
              {urlScanLoading ? (
                <div className="position-absolute top-50 start-50 translate-middle">
                  <div className="d-flex flex-column">
                    <div className="col"></div>            
                    <div className="col"><WebLoader /></div>
                    <div className="col"><WebLoader2 /></div> 
                  </div>
                </div>
              ) : (
                <div className='scrollbar-all-primary d-flex flex-row' style={{ height: viewHeight }}>
                  <WebDashboardView />
                  <div className='d-flex flex-column ps-2'>
                    {urlAssets && (
                      <>
                        {urlAssets.map((asset, index) => (
                          <div
                            key={index}
                            onClick={() => setUrlIndex(index)}
                            style={{fontSize: 30, color: urlIndex === index ? '#a00000' : '#344266'}}
                          ><i className={`bi bi-${index+1}-square-fill`}/>
                          </div>
                        ))}
                        <div onClick={() => {
                            setUrlDashboardView(false);
                            setUrlHeader(false);
                          }}
                          style={{fontSize: 30, color: '#6c757d'}}
                        ><i className="bi bi-plus-square-fill"/>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default WebDashboard;

const WebScanCard = () => {
  const { userId } = useAuth();
  const {
    setUrlScanLoading, setUrlAssets, setUrlVulTableAll, setUrlEpssAll,
    setUrlIndex, setUrlDashboardView, setUrlHeader
  } = useUser();
  
  const [url, setUrl] = useState('');
  const handleSubmit = async () => {
    setUrlHeader(false);
    setUrlDashboardView(true);
    setUrlScanLoading(true);
    try {
      const getResponseUrl = await postUrl(userId, url);      
      const assets_count = getResponseUrl.urlAssets_count;
      setUrlIndex(assets_count);
      setUrlHeader(true);
      await loadUrlData(userId);      
    } catch (error) {
      setUrlDashboardView(false);
      console.error('Error in handleSubmit:', error);
      // setError('An error occurred while submitting the form');
    } finally {
      setUrlScanLoading(false);
    }
  };

  const loadUrlData = async (userId) => {
    try {
      const getUrlAssets_json = await getUrlAssets(userId);
      const vulnerabilities = await getUrlVulnerabilities(userId);
      const epss = await getUrlEpss(userId);
      setUrlAssets(getUrlAssets_json);
      setUrlVulTableAll(vulnerabilities);
      setUrlEpssAll(epss);
    } catch (error) {
      console.error('Error fetching URL vulnerabilities:', error);
      throw error;
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100 scan-card">
      <div className="col-8 card shadow-lg border-1 rounded-4"
        style={{ height: 'calc(100% - 10%)', width: '76%' }}>
        <div className="d-flex flex-row align-items-center h-100 rounded-4">
          <div className="p-3 w-50 mb-5" style={{ fontSize: '14px' }}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {/* Title */}
              <div className='fs-1 fw-bold text-center heading'>
                Scan Your Url
              </div>
              {/* Description */}
              <div className='fs-6 text-center mb-5 subheading' style={{width: 280}}>
                Enter the Url address below to scan<br/>for potential vulnerabilities.
              </div>
              {/* Input Field */}
              <div className="mb-2 input-group rounded-2" style={{ height: 43, width: 260 }}>
                <input
                  type="text"
                  placeholder="Enter url"
                  className="p-2 text-center form-control"
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>
              <div className='mb-3'>ex: https://www.example.com</div>
              {/* Submit Button */}
              <div className="d-flex justify-content-center">
                <button
                  className="btn border-0 shadow-sm"
                  type='submit'                  
                  style={{ width: 260 }}
                  onClick={handleSubmit}>Submit
                </button>
              </div>
            </div>
          </div>
          <div className="h-100 w-50"
            style={{ background: '#001034', borderRadius: '0 1rem 1rem 0' }}>
            <video 
              className="img-fluid h-100"
              style={{ objectFit: 'cover', borderRadius: '0 1rem 1rem 0' }}
              src={Vid5} 
              alt="video" 
              autoPlay 
              loop 
              muted 
            />
          </div>
        </div>
      </div>        
    </div>
  );
};

function WebDashboardView({title}) {
  const { mainBodyHeight, mainBodyWidth, viewHeight } = useDesign();
  const { urlAssets, urlVulTableAll, urlEpssAll, 
    setUrlEpssTable, showEpssCard,
    setUrlScanLoading, urlIndex } = useUser();
  const [urlVulTable, setUrlVulTable] = useState(null);  
  const [TVulScore, setTVulScore] = useState(0);
  const [CVulScore, setCVulScore] = useState(0);
  const [saveScore, setSaveScore] = useState(0);
  const [auditResult, setAuditResult] = useState('');
  
  const printRandomText = (textList) => {
    if (textList.length === 0) {
      return null;
    }
    const randomIndex = Math.floor(Math.random() * textList.length);
    const selectedText = textList[randomIndex];
    textList.splice(randomIndex, 1);
    return selectedText;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadWebData(urlIndex);
      } catch (error) {
        console.error('Error in loadWebData:', error);
      }
    };
    fetchData();
  }, [urlIndex]);

  const loadWebData = async (urlIndex) => {
    if (urlAssets && urlVulTableAll) {
      const currentUuid = urlAssets[urlIndex]?.url_uuid;
      const filteredVulTable = urlVulTableAll.filter(vul => (vul.uuid === currentUuid && !vul.level == 0));
      const filteredEpssTable = urlEpssAll.filter(vul => (vul.uuid === currentUuid && vul.baseSeverity != null));
      const filderedResult_soln = filteredVulTable.map((item, index) => item.solution);
      const cVul = filteredVulTable.filter(vul => vul.level >= 9).length;
      const SS = urlAssets[urlIndex]?.save_score;
      const AuditResult = printRandomText(filderedResult_soln);
      setUrlVulTable(filteredVulTable);
      setUrlEpssTable(filteredEpssTable);
      setTVulScore(filteredVulTable.length);
      setCVulScore(cVul?cVul:0);
      setSaveScore(SS?SS:0);
      setAuditResult(AuditResult);
      
      if (!filteredVulTable) {
        setUrlScanLoading(true);
      } else {
        setUrlScanLoading(false);
      }
    }
  };

  const props = { 
    title, mainBodyHeight, mainBodyWidth,
    viewHeight, urlVulTable
  };
  
  return (
    <>
      {showEpssCard && <EpssCard  />}
      <div className='d-flex justify-content-left'>
        <RiskExposurelist {...props} />
        <div className='scrollbar-all-primary'
          style={{ height: viewHeight }}>
          <div className='d-flex flex-row h-auto'>
            <div className='d-flex flex-column'>
              <Vulnerabilities setScore={TVulScore}/>
              <CriticalVul setScore={CVulScore}/>
            </div>
            <div className='d-flex flex-column'>
              <SaveScore setScore={saveScore}/>
              <Solutions setText={auditResult}/>
            </div>
          </div>
          <WebGraph />
        </div> 
      </div>
    </>
  );
};

const WebGraph = () => {
  const [webGraphData, setWebGraphData] = useState([]);
  const api_base_url = process.env.REACT_APP_API_BASE_URL_LOCAL;
  const api_key = process.env.REACT_APP_API_KEY;  
  const web_analytics_graph = `${api_base_url}/api/${api_key}/analytics/web_analytics_graph_data`;
  const tooltipKeyMapping = {
    TWV: 'Total Vulnerability',
    CWV: 'Critical Vulnerability',
    SS: 'Save Score',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const web_response = await fetch(web_analytics_graph);
        const web_data = await web_response.json();
        setWebGraphData(processDataByPeriod(web_data, 'Last 7 days'));
        console.log('Web Graph Data:', web_data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }; fetchData();
  }, []);
  
  return (
    <div className='pe-2'>
      <div className='card rounded-4 fw-bold px-3'
        style={{ fontSize: 14, height: 400 }}>
        <div className='card-body'>
          <ResponsiveContainer>
            <LineChart data={webGraphData}>
              <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.4} />
              <XAxis 
                dataKey="Date" 
                tickFormatter={(date) => {
                  const formattedDate = new Date(date);
                  return formattedDate.toLocaleDateString('en-US');
                }}
              />
              <Tooltip 
                formatter={(value, name) => {
                  return [`${value}`, tooltipKeyMapping[name]];
                }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="TWV" 
                stroke="#8884d8" 
                strokeWidth={2} 
                activeDot={{ r: 4 }}  
              />
              <Line 
                type="monotone" 
                dataKey="CWV" 
                stroke="#cf0000" 
                strokeWidth={2} 
                activeDot={{ r: 4, stroke: 'red', strokeWidth: 2, fill: 'red' }}
              />
              <Line 
                type="monotone" 
                dataKey="SS" 
                stroke="#ffd766" 
                strokeWidth={2} 
                activeDot={{ r: 4 }} 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );  
};

const WebPieChart = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      
    </>
  );  
};
