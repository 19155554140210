import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import { postMalUrl, postMalFile } from "../api/api-post";
import { processData, processDataByPeriod } from '../utils/processDate';
import Loader2 from "./components/Loaders/Loader-2/Loader-2";
import OnLoadUrl from './components/Malware/onLoadUrl';
import OnLoadFile from './components/Malware/onLoadFile';
import { 
  ResponsiveContainer, CartesianGrid, LineChart, 
  XAxis, Line, Tooltip, Legend 
} from 'recharts';

const MalwareScanner =({title})=>{
  const { viewHeight, mainBodyHeight, mainBodyWidth } = useDesign();
  const {
    malwareAssets, setMalwareAssets,
  } = useUser();
  const [malwareScanGraphView, setMalwareScannerGraphView] = useState(false);
  const props={
    title, mainBodyHeight, mainBodyWidth, malwareAssets,
    viewHeight, setMalwareScannerGraphView
  };

  useEffect(() => {
    return () => {
      if(!malwareAssets)
        setMalwareScannerGraphView(false);
      else
        setMalwareScannerGraphView(true);
    };
  }, []);

  return(
    <>
      <div className="h-100">
        <div className='p-2'></div>
        {/* <div className='p-1'><h4>{title}</h4></div> */}
        {!malwareScanGraphView && (
          <div className='p-1 col-12'>
            <div className="mt-1" style={{height: viewHeight, paddingRight: 10}}>
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <MalwareScanCard {...props}/>
              </div>
            </div>
          </div>
        )}
        {malwareScanGraphView && (
          <div className='scrollbar-all-primary' style={{height: viewHeight, paddingRight: 10}}>
          <MalwareScanHistoryView {...props}/>
          </div>
        )}
      </div>
    </>
  )
}

export default MalwareScanner;

function MalwareScanCard() {
  const { userId } = useAuth();
  const {
    malwareScanLoading, setMalwareScanLoading,
  } = useUser();
  const { viewHeight } = useDesign();
  const [activeTab, setActiveTab] = useState('File');
  const [activeTabResult, setActiveTabResult] = useState('Detection');
  const [showData, setShowData] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  const [fileInput, setFileInput] = useState(null);
  const [buttonText, setButtonText] = useState('Choose File');
  const fileInputRef = useRef(null);

  const tabColor = '#344266';
  const tabStyle = {
    width: 160,
    height: 50,
    cursor: 'pointer',
    borderRadius: 0,
    border: 0,
    outline: 0,
    fontWeight: 600,
  };
  const fontStyle = { fontSize: 15, fontWeight: 500 };

  const handleClick = (tab) => {        //Change the active tab when a tab is clicked
    setActiveTab(tab);
  };
  const handleClickResult = (tab) => {  //Change the result active tab when a tab is clicked
    setActiveTabResult(tab);
  };

  const handleButtonClick = () => {
    if (!fileInput) {
      // Trigger file input click if no file is selected
      fileInputRef.current?.click();
    } else if (fileInput && !malwareScanLoading) {
      // Submit file if a file is selected and not uploading
      handleFileSubmit();
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileInput(selectedFile); // Save the selected file
      setButtonText('Submit File'); // Update the button text
    }
  };
  

  const handleFileSubmit = async () => {
    if (fileInput) {
      setMalwareScanLoading(true);     
      try {
        const data = await postMalFile(userId, fileInput);
        setShowData(data);
        console.log(data);
        setFileInput(null); // Clear the file after submission
        setButtonText('Choose File'); // Reset button text
      } catch (error) {
        console.error('Error during file submission:', error);
      } finally {
        setMalwareScanLoading(false); // Reset malwareScanLoading state
      }
    }
  };

  const handleUrlSubmit = async () => {
    if (activeTab === 'Url' && urlInput) {
      setMalwareScanLoading(true);
      try {
        const data = await postMalUrl(userId, urlInput);
        setShowData(data);
        console.log(data);
        setUrlInput('');  // Clear the URL input after submission
      } catch (error) {
        console.error('Error during URL submission:', error);
      } finally {
        setMalwareScanLoading(false);
      }
    }
  };

  const props = {
    viewHeight, activeTabResult, 
    handleClickResult, handleClick, showData
  };  

  return (
    <>
      {malwareScanLoading ? (<Loader2/>):(
        <>
          {!showData ? (
            <>
              <div className="text-center">
                <h1 style={{
                  fontFamily: '"Jersey 20 Charted", sans-serif',
                  fontSize: 60,
                  fontWeight: 400,
                  fontStyle: "normal",
                }}>MALWARE SCANNER</h1>
                <h6>powered by <b>VIRUSTOTAL</b></h6>
              </div>
              <div className="py-3 d-flex justify-content-center align-items-center">
                <button className="py-3 px-4 btn" data-tooltip-text="Max size 650MB"
                  style={activeTab === 'File'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('File')}>File
                </button>
                <button className="py-3 px-4 btn"
                  style={activeTab === 'Url'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('Url')}>Url
                </button>
                <button className="py-3 px-4 btn"
                  style={activeTab === 'Search'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('Search')}>Search
                </button>
              </div>
              <div className="d-flex justify-content-center flex-column" width="480px">
                <div style={{ height: 180 }}>
                  {activeTab === 'File' && (
                    <div className="d-flex flex-column justify-content-center fade-in">
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-file-earmark-break-fill" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="py-2 px-4 btn malware-scanner-btn text-white"
                          style={{
                            width: 160,
                            fontSize: '16px',
                            backgroundColor: '#302154',
                            border: 'none',
                          }}
                          onClick={handleButtonClick}
                          disabled={malwareScanLoading} // Disable button during upload
                        >{buttonText} {/* Show dynamic button text */}
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  )}
                  {activeTab === 'Url' && (
                    <div className="d-flex justify-content-center flex-column fade-in">        
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-link-45deg" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="col-6">
                          <input
                            style={fontStyle}
                            type="text"
                            className="form-control text-center"
                            placeholder="Scan a URL"
                            value={urlInput}
                            onChange={(e) => setUrlInput(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button className="py-2 px-4 btn malware-scanner-btn text-white" 
                          onClick={handleUrlSubmit}>
                          Submit URL
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === 'Search' && (
                    <div className="d-flex justify-content-center flex-column fade-in">
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-search" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="col-6">
                          <input
                            style={fontStyle}
                            type="text"
                            className="form-control text-center"
                            placeholder="Search for Domain, IP Address, URL"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button className="py-2 px-4 btn malware-scanner-btn text-white" 
                          onClick={handleUrlSubmit}>
                          Submit URL
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center text-center pt-2 fade-in">
                  <div className="col-6" style={{ fontSize: '12px', fontWeight: 600 }}>
                    <br />
                    By submitting data above, you are agreeing to our <a href="/Terms-Conditions">Terms of Service</a> and <a href="/Privacy-Policy">Privacy</a> Notice, and to the sharing of your Sample submission with the security community. Please do not submit any personal information; VirusTotal is not responsible for the contents of your submission. Learn more.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {activeTab === 'File' && (<OnLoadFile {...props} />)}
              {activeTab === 'Url' && (<OnLoadUrl {...props} />)}
              {activeTab === 'Search' && (<OnLoadUrl {...props} />)}
            </>
          )}
        </>
      )}
    </>
  );
}

function MalwareScanHistoryView({setMalwareScannerGraphView}){
  const { malwareAssets } = useUser();
  console.log('Malware Assets:', malwareAssets);
  return(
    <>
      <div className='fs-3 fw-medium'>Scan History</div>         
      <div className='d-flex flex-row'>
        <ScanGraph />      
        <TotalReport setMalwareScannerGraphView={setMalwareScannerGraphView}/>
      </div>
    </>
  )
}

const ScanGraph = () => {
  const { malwareAssets } = useUser();
  const [data, setData] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState('Last 28 days');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData(processDataByPeriod(malwareAssets, selectedDuration));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [malwareAssets, selectedDuration]); // Re-run when malwareAssets or selectedDuration changes

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };

  return (
    <>
      <div className='d-flex flex-column'>        
        <div className='pe-2'>
          <div className='card rounded-4 fw-bold px-3 mt-4'
            style={{ fontSize: 14, height: `67vh`, width: `36vw` }}>            
            <div className='card-body'>
              <div className="dropdown py-3" type="button">
                <button className="btn btn-secondary dropdown-toggle text-start px-4 border-0" 
                  type="button" id="durationDropdown" 
                  data-bs-toggle="dropdown" aria-expanded="false"
                  style={{
                    borderRadius: 8,
                    width: 160, height: 32, 
                    fontSize: 13, fontWeight: 500,
                  }}>
                  {selectedDuration}
                </button>
                <ul className="dropdown-menu shadow border-0" 
                  aria-labelledby="durationDropdown"
                  style={{cursor: 'pointer', fontSize: 13, fontWeight: 500}}>
                  <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 7 days')}>Last 7 days</span></li>
                  <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 28 days')}>Last 28 days</span></li>
                  <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 90 days')}>Last 90 days</span></li>
                  <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 365 days')}>Last 365 days</span></li>
                  <li><span className="dropdown-item" onClick={() => handleDurationChange('Lifetime')}>Lifetime</span></li>
                </ul>
              </div>
              <ResponsiveContainer height="83%">
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.4} />
                  <XAxis 
                    dataKey="date" 
                    tickFormatter={(date) => {
                      const formattedDate = new Date(date);
                      return formattedDate.toLocaleDateString('en-US');
                    }}
                  />
                  <Tooltip 
                    formatter={(value, name) => {
                      const keyMapping = { suspicious: 'Suspicious', harmless: 'Harmless' };
                      return [`${value}`, keyMapping[name] || name];
                    }}
                  />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="suspicious" 
                    stroke="#ff7300" 
                    strokeWidth={2} 
                    activeDot={{ r: 4 }}  
                  />
                  <Line 
                    type="monotone" 
                    dataKey="harmless" 
                    stroke="#82ca9d" 
                    strokeWidth={2} 
                    activeDot={{ r: 4 }}  
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );   
};

const TotalReport = ({ setMalwareScannerGraphView }) => {
  const { malwareAssets } = useUser();

  // Calculate the totals for each field
  const totals = malwareAssets.reduce(
    (acc, item) => {
      acc.malicious += item.malicious || 0;
      acc.harmless += item.harmless || 0;
      acc.suspicious += item.suspicious || 0;
      acc.undetected += item.undetected || 0;
      return acc;
    },
    { malicious: 0, harmless: 0, suspicious: 0, undetected: 0 }
  );

  return (
    <>
      <div className='card rounded-4 px-3 mt-4' style={{ height: `67vh`, width: 360 }}>
        <div className='card-body'>
          <div className='d-flex flex-column justify-content-between h-100'>
            <div className='mt-4'>
              <div className='d-flex justify-content-between'>             
                <div className='fw-medium'>
                  <div>Malicious Detected:</div>
                  <div>Harmless Detected:</div>
                  <div>Suspicious Detected:</div>
                  <div>Undetected:</div>
                </div>
                <div>
                  <div>{totals.malicious}</div>
                  <div>{totals.harmless}</div>
                  <div>{totals.suspicious}</div>
                  <div>{totals.undetected}</div>
                </div>
              </div>             
            </div>
            <hr className='m-0 mt-2' />
            <div className='d-flex flex-column justify-content-start'>
              <div className='fs-4'>Scan History</div>
              <div className='scrollbar-all-primary' style={{ height: 'calc(60vh - 240px)' }}>
                {malwareAssets
                  .sort((a, b) => new Date(b.scan_time) - new Date(a.scan_time)) // Sort by scan_time descending
                  .slice(0, 6) // Take the top 6
                  .map((asset, index) => (
                    <div 
                      className='d-flex flex-row justify-content-between text-white rounded p-2 mb-2 w-100' 
                      style={{ fontSize: 12, background: '#6c757d' }}
                      key={index}>
                      {/* Truncated URL */}
                      <div className='text-truncate fw-medium' style={{ width: 'calc(100% - 100px)', overflow: 'hidden' }}>
                        {asset.url.split('/home/anonymous/Desktop/save-project/backend/').pop()}
                      </div>
                      {/* Fixed-width date */}
                      <div className='ps-1' style={{ width: 100, textAlign: 'left' }}>
                        {new Date(asset.scan_time).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <hr className='m-0 mb-2' />
            <button
              className='btn text-white fw-medium fs-5 w-100 mb-4'
              style={{ backgroundColor: '#1d1d1d' }}
              onClick={() => setMalwareScannerGraphView(false)}>
              Scan Again
            </button>
          </div>
        </div>
      </div>
    </>
  );
};