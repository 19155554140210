import React from "react";
import Loader from "./components/Loaders/Loader-1/Loader-1";

const Test = () =>{
  return(
    <>
      <Loader/>
    </>
  );
};

export default Test;

