import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../../context/contextAuth';
import popperIcons from '../../../config/popperIcons';
import './style.css';

const ExternalLinksPopup = () => {
  const { upgradePlan } = useAuth();
  const [showIcons, setShowIcons] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setShowIcons(!showIcons);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowIcons(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown">
      <div>
        <button className="btn btn-secondary bg-transparent border-0 position-relative"
          type="button" onClick={handleClick}>
          <i className="bi bi-grid-3x3-gap-fill" style={{ fontSize: '1.5rem' }} />
        </button>
      </div>
      {showIcons && <ShowFlexboxIcons upgradePlan={upgradePlan} />}
    </div>
  );
};
export default ExternalLinksPopup;

const ShowFlexboxIcons = ({ upgradePlan }) => {
  const isUpgraded = upgradePlan;

  return (
    <div className="position-absolute bg-light rounded-5 shadow-lg soverflow-y-auto"
      style={{
        width: 350,
        maxHeight: 500,
        right: -78,
        top: 50,
        transition: 'all 0.3s ease-in-out',
        border: 'solid 1px rgba(48, 33, 88, 0.3)',
        zIndex: 1000,
      }}>
      {/* Widget Header */}
      <div className="d-flex justify-content-center align-items-center text-white rounded-top-5 py-2"
        style={{ background: '#302154', height: 60 }}>
        <h5 className="m-0">Enterprise Tools</h5>
      </div>

      {/* Widget Content */}
      <div className={`py-2 scrollbar-notify w-100 ${!isUpgraded ? 'text-center' : 'ps-3 pe-3'}`}>
        {isUpgraded ? (
          <div className="row justify-content-center p-3">
            {popperIcons.map((icon, index) => (
              <div key={index} className="col-4 d-flex justify-content-center">
                <button className="btn col-12 border-0 rounded-4 p-3"
                  style={{
                    height: 90,
                    width: 90,
                    color: '#302154',
                    background: '#f7f8f9',
                    cursor: 'pointer',
                  }}>
                  <i className={icon.iconClass} style={{ fontSize: 30 }} />
                  <p className="mb-0" style={{ fontSize: 12 }}>{icon.name}</p>
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="py-5 text-center text-muted">
            <i className="bi bi-tools fs-3"></i>
            <p className="m-0">Feature Coming Soon</p>
          </div>
        )}
      </div>

      {/* Pro Access Button */}
      {!isUpgraded && (
        <div className="d-flex justify-content-center align-items-center py-3"
          style={{ height: 100 }}>
          <button type="button" className="btn btn-primary border-0 rounded-5"
            style={{
              width: 200,
              height: 50,
              fontSize: 16,
            }}>
            Get Pro Access
          </button>
        </div>
      )}
    </div>
  );
};