import React, { useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth.js';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import cloudItems from '../config/cloudItems';
import RiskExposureList from "./components/Cards/RiskExposureList-Cloud";
import Vulnerabilities from "./components/Cards/c-item-0-vulnerabilities";
import CriticalVul from "./components/Cards/c-item-1-critical-vulnerability";
import { postGCPKeys, postAWSKeys, postAzureKeys } from '../api/api-post.js';
import { getCloudAssets, getCloudAWSAssets, getCloudAzureAssets, getCloudGCPAssets } from '../api/api-get.js';

const setLabelColor={width: 120, fontSize: 16, fontWeight: 500, paddingLeft: 14, backgroundColor: '#3A3756'}
const btnStyle={backgroundColor: '#3A3756', width: 220, height: 46}
const CloudDashboard = ({title}) => {
  const { user, upgradePlan } = useAuth();
  const { viewHeight, toggleUpgradeCard, mainBodyHeight, mainBodyWidth } = useDesign();
  const { cloudLoading, 
    cloudAssets, setCloudAssets,    
    cloudAWSAssets, setCloudAWSAssets,
    cloudAzureAssets, setCloudAzureAssets,
    cloudGCPAssets, setCloudGCPAssets
  } = useUser();
  const [selectedCard, setSelectedCard] = useState('');
  const [cardClick, setCardClick] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  const props={
    title, viewHeight, mainBodyWidth, mainBodyHeight,
    selectedCard, setSelectedCard,
    cardClick, setCardClick,
    showDashboard, setShowDashboard,
    user, cloudAssets, setCloudAssets
  }

  useEffect(()=>{
    if(!cloudLoading){
      try{
        getData(); 
      }catch(error){
        console.log('Failed to get Data: ', error);
        throw(error);
      }
    }    
  },[]);  
  
  const getData = async() =>{
    if(user && cloudAssets){
      const userId = user?.id;
      const aws_uuid = cloudAssets?.aws_uuid;
      const azure_uuid = cloudAssets?.azure_uuid;
      const gcp_uuid = cloudAssets?.gcloud_uuid;
      const misconfig_AWS = await getCloudAWSAssets(userId, aws_uuid);
      const misconfig_Azure = await getCloudAzureAssets(userId, azure_uuid);
      const misconfig_GCP = await getCloudGCPAssets(userId, gcp_uuid);
      if (!misconfig_AWS || !misconfig_Azure || !misconfig_GCP){
        setShowDashboard(false);
      }else setShowDashboard(true);
    }else setShowDashboard(false);     
  }
  const toggleCard =()=>{
    if(!upgradePlan){
      toggleUpgradeCard();
    }
  }

  return(
    <>
      <div className='h-100 cloud-dashboard'>
        {}
        <div className='p-1 pb-2'><h4>{title}</h4></div>     
        <div className={`d-flex ${cardClick?'justify-content-left':'justify-content-center'}`}
          style={{height: viewHeight}} onClick={toggleCard}>
          <div className={`col-12 d-flex ${cardClick?'justify-content-left':'justify-content-center'} 
            ${!upgradePlan?'disable':''}`}>
            <div className={`${cardClick?'d-flex w-100':'d-flex align-items-center scrollbar-all-primary'}`}>
              <div className={`${cardClick?'col-8':'visually-none'}`}
                style={{
                  transform: cardClick?'scale(1)':'scale(0.9)',
                  transition: cloudAssets?'':'all 0.6s'}}>
                {selectedCard === 'AWS' && <AWSFunc {...props}/>}
                {selectedCard === 'GCP' && <GCPFunc {...props}/>}
                {selectedCard === 'Azure' && <AzureFunc {...props}/>}
              </div>
              <div className={`${cardClick?'scrollbar-all-primary':''}`}
                style={{width: cardClick? 220:'auto', transition: 'all 0.6s',}}>
                <div className={cardClick?'col-6':'col-auto'}>
                  <CloudBtnFunc {...props}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CloudDashboard;

function CloudBtnFunc({setSelectedCard, cardClick, setCardClick, cloudAssets}) {
  const [selectedButton, setSelectedButton] = useState(null);
  useEffect(()=>{
    if(cloudAssets){
      if(cloudAssets?.gcloud_access_key && cloudAssets?.gcloud_secret_key){
        setSelectedButton('GCP');
        setSelectedCard('GCP');
        setCardClick(true);
      }
      else if(cloudAssets?.azure_access_key && cloudAssets?.azure_secret_key){
        setSelectedButton('Azure');
        setSelectedCard('Azure');
        setCardClick(true);
      }
      else if(cloudAssets?.aws_access_key && cloudAssets?.aws_secret_key){
        setSelectedButton('AWS');
        setSelectedCard('AWS');
        setCardClick(true);
      }
    }
  },[cloudAssets]);
  
  const handleButtonClick = (button) => {
    setSelectedButton(button.title);
    setSelectedCard(button.title);
    setCardClick(true);
  };
  return (
    <>
      <div className="w-100">
        <div className="row">
          {cloudItems.map((button, index) => (
            <div className={`col-auto text-center px-4 ${cardClick ?'mx-3':''}`} 
              key={index}>
              <div className={`card ${cardClick ? 'px-1' : 'px-2'}
                shadow-sm m-1 rounded-4 position-relative`}
                style={{
                  height: cardClick ? 100 : 300,
                  width: cardClick ? 100 : 200,
                  transition: cloudAssets?'':'all 0.3s',                  
                  background: selectedButton === button.title ? '#3A3756' : '#f6f7f8',
                }}
                onClick={() => handleButtonClick(button)}>
                <div className={`d-flex ${cardClick ? '' : 'flex-column'} 
                  justify-content-between align-items-center h-100`}>
                  <div style={{ width: 'auto' }}>
                    <img className={`p-1 ${cardClick ? '' : 'py-5'} w-100`}
                      src={button.src} alt={button.alt} />
                  </div>
                  {!cardClick ? (
                    <div className='rounded-3 my-2'
                      style={{ width: '100%', height: 40, background: '#3A3756' }}>
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <span className="text-center text-white d-flex justify-content-center align-content-center">
                          {button.title}
                        </span>
                      </div>
                    </div>
                  ) : (<></>)}
                </div>
                <div>
                  <span
                    className={`position-absolute top-0 start-100 badge rounded-circle bg-danger p-1`}
                    style={{ marginLeft: -18, marginTop: 10 }}>
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
const GCPFunc = ({ viewHeight, user, cloudAssets, setCloudAssets }) =>{
  const { cloudVulTableAll } = useUser();
  const [error, setError] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [gcloudAccessKey, setGCPAccessKey] = useState('');
  const [gcloudSecretKey, setGCPSecretKey] = useState('');

  useEffect(() => {
    if (cloudAssets?.gcloud_access_key && cloudAssets?.gcloud_secret_key) {
      setShowDashboard(true);
    }else{setShowDashboard(false);}
  },[cloudAssets]);

  const handleScan = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior if applicable    
    try {
      const userId = user?.id; // Ensure user and id exist  
      if (!userId) {
        setError('User ID does not exist. Please sign in again.', error);
        return;
      }    
      if (!gcloudAccessKey || !gcloudSecretKey) {
        setError('Please provide all required GCP keys.',error);
        return;
      }
      await postGCPKeys(userId, gcloudAccessKey, gcloudSecretKey);
      const getAssets = await getCloudAssets(userId);
      setCloudAssets(getAssets);
      if (cloudAssets?.gcloud_access_key && cloudAssets?.gcloud_secret_key) {
        setShowDashboard(true);
      }
      setError(null);
    } catch (error) {
      setError('An error occurred while processing the keys', error);
      // console.error(error);
    }
  };
  
  const props = { 
    viewHeight, cloudVulTableAll
  };

  return (
    <>
      <div className="w-auto">
        {!showDashboard && <div className="row py-5">
          <form onSubmit={handleScan}>
            <div className="mb-3" style={{paddingRight: 160}}> 
              <div className="input-group">
                <span className="input-group-text text-white accessKeyInputGroup"
                  style={setLabelColor}>Access Key:</span>
                <input id="accessKey"
                  className="form-control col-8"
                  type="text" aria-label="Access Key"
                  aria-describedby="accessKeyInputGroup"                
                  value={gcloudAccessKey}
                  onChange={(e) => setGCPAccessKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3" style={{paddingRight: 160}}>
              <div className="input-group">
                <span className="input-group-text text-white secretInputGroup"
                  style={setLabelColor}>Secret Key:</span>
                <input id="secret"
                  className="form-control col-8"
                  type="text" aria-label="Secret"
                  aria-describedby="secretInputGroup"                
                  value={gcloudSecretKey}
                  onChange={(e) => setGCPSecretKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="d-flex justify-content-end py-4"
              style={{paddingRight: 160}}>             
              <button type="submit" 
                className="btn text-white border-0 cloud-scan-btn
                d-flex justify-content-center align-items-center"
                style={btnStyle}>
                  <i className="bi bi-cloud-fill" style={{paddingRight: 10}} />
                  Google Cloud Scan            
              </button>
            </div>
          </form>
        </div>}
        {showDashboard &&
          <div className="d-flex justify-content-left">
            <div className='p-1 d-flex' 
              style={{height: viewHeight, width: 552}}>
              <RiskExposureList {...props}/>
            </div> 
            <div className='scrollbar-all-primary col-4'
              style={{height: viewHeight}}>
                <div className="row p-1 px-2 flex-column">
                  <div className="col mb-3">
                    <Vulnerabilities setScore={setVulScoreGCP}/>
                  </div>
                  <div className="col mb-3">
                    <CriticalVul setScore={setCVulScoreGCP}/>
                  </div>
                </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
const AzureFunc = ({ viewHeight, user, cloudAssets, setCloudAssets }) =>{
  const [error, setError] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [azureAccessKey, setAzureAccessKey] = useState('');
  const [azureSecretKey, setAzureSecretKey] = useState('');

  useEffect(() => {
    if (cloudAssets?.azure_access_key && cloudAssets?.azure_secret_key) {
      setShowDashboard(true);
    }else{setShowDashboard(false);}
  },[cloudAssets]);

  const handleScan = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior if applicable
    try {
      const userId = user?.id; // Ensure user and id exist
      if (!userId) {
        setError('User ID does not exist. Please sign in again.', error);
        return;
      }
      if (!azureAccessKey || !azureSecretKey) {
        setError('Please provide all required Azure keys.', error);
        return;
      }
      await postAzureKeys(userId, azureAccessKey, azureSecretKey);
      const getAssets = await getCloudAssets(userId);
      setCloudAssets(getAssets);
      if (cloudAssets?.azure_access_key && cloudAssets?.azure_secret_key) {
        setShowDashboard(true);
      }
      setError(null); // Clear any previous errors
    } catch (error) {
      setError('An error occurred while processing the Azure keys', error);
      // console.error(error);
    }
  };
  
  return (
    <>
      <div className="w-auto">
        {!showDashboard && <div className="row py-5">
          <form onSubmit={handleScan}>
            <div className="mb-3" style={{paddingRight: 160}}> 
              <div className="input-group">
                <span className="input-group-text text-white accessKeyInputGroup"
                  style={setLabelColor}>Access Key:</span>
                <input id="accessKey"
                  className="form-control col-8"
                  type="text" aria-label="Access Key"
                  aria-describedby="accessKeyInputGroup"                
                  value={azureAccessKey}
                  onChange={(e) => setAzureAccessKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3" style={{paddingRight: 160}}>
              <div className="input-group">
                <span className="input-group-text text-white secretInputGroup"
                  style={setLabelColor}>Secret Key:</span>
                <input id="secret"
                  className="form-control col-8"
                  type="text" aria-label="Secret"
                  aria-describedby="secretInputGroup"                
                  value={azureSecretKey}
                  onChange={(e) => setAzureSecretKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="d-flex justify-content-end py-4"
              style={{paddingRight: 160}}>             
              <button type="submit"
                className="btn text-white border-0 cloud-scan-btn
                d-flex justify-content-center align-items-center"
                style={btnStyle}>
                  <i className="bi bi-cloud-fill" style={{paddingRight: 10}} />
                  Azure Cloud Scan            
              </button>
            </div>
          </form>
        </div>}
        {showDashboard &&
          <div className="d-flex">
            <div className='p-1 d-flex' 
              style={{height: viewHeight, width: 552}}>
              <RiskExposureList viewHeight={viewHeight}/>
            </div> 
            <div className='scrollbar-all-primary col-4'
              style={{height: viewHeight}}>
                <div className="row p-1 px-2 flex-column">
                  <div className="col mb-3">
                    <Vulnerabilities setScore={setVulScoreGCP}/>
                  </div>
                  <div className="col mb-3">
                    <CriticalVul setScore={setCVulScoreGCP}/>
                  </div>
                </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
const AWSFunc = ({ viewHeight, user, cloudAssets, setCloudAssets }) =>{
  const [error, setError] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [awsAccessKey, setAwsAccessKey] = useState('');
  const [awsSecretKey, setAwsSecretKey] = useState('');

  useEffect(() => {
    if (cloudAssets?.aws_access_key && cloudAssets?.aws_secret_key) {
      setShowDashboard(true);
    }else{setShowDashboard(false);}
  },[cloudAssets]);

  const handleScan = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior if applicable
    try {
      const userId = user?.id; // Ensure user and id exist
      if (!userId) {
        setError('User ID does not exist. Please sign in again.', error);
        return;
      }
      if (!awsAccessKey || !awsSecretKey) {
        setError('Please provide all required AWS keys.', error);
        return;
      }

      await postAWSKeys(userId, awsAccessKey, awsSecretKey);
      const getAssets = await getCloudAssets(userId);
      setCloudAssets(getAssets);

      setError(null); // Clear any previous errors
    } catch (error) {
      setError('An error occurred while processing the AWS keys', error);
      // console.error(error);
    }
  };


  
  return (
    <>
      <div className="w-auto">
        {!showDashboard && <div className="row py-5">
          <form onSubmit={handleScan}>
            <div className="mb-3" style={{paddingRight: 160}}> 
              <div className="input-group">
                <span className="input-group-text text-white accessKeyInputGroup"
                  style={setLabelColor}>Access Key:</span>
                <input id="accessKey"
                  className="form-control col-8"
                  type="text" aria-label="Access Key"
                  aria-describedby="accessKeyInputGroup"                
                  value={awsAccessKey}
                  onChange={(e) => setAwsAccessKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3" style={{paddingRight: 160}}>
              <div className="input-group">
                <span className="input-group-text text-white secretInputGroup"
                  style={setLabelColor}>Secret Key:</span>
                <input id="secret"
                  className="form-control col-8"
                  type="text" aria-label="Secret"
                  aria-describedby="secretInputGroup"                
                  value={awsSecretKey}
                  onChange={(e) => setAwsSecretKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="d-flex justify-content-end py-4"
              style={{paddingRight: 160}}>             
              <button type="" 
                className="btn text-white border-0 cloud-scan-btn
                d-flex justify-content-center align-items-center"
                style={btnStyle}>
                  <i className="bi bi-cloud-fill" style={{paddingRight: 10}} />
                  AWS Cloud Scan
              </button>
            </div>
          </form>
        </div>}
        {showDashboard &&
          <div className="d-flex">
            <div className='p-1 d-flex' 
              style={{height: viewHeight, width: 552}}>
              <RiskExposureList viewHeight={viewHeight}/>
            </div> 
            <div className='scrollbar-all-primary col-4'
              style={{height: viewHeight}}>
                <div className="row p-1 px-2 flex-column">
                  <div className="col mb-3">
                    <Vulnerabilities setScore={setVulScoreGCP}/>
                  </div>
                  <div className="col mb-3">
                    <CriticalVul setScore={setCVulScoreGCP}/>
                  </div>
                </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

const setVulScoreGCP=3;
const setCVulScoreGCP=1;
const setVulScoreAzure=4;
const setCVulScoreAzure=0;
const setVulScoreAWS=6;
const setCVulScoreAWS=0;