import React from "react";

const OpenPorts=({ setPortTable }) =>{
  return(
    <div className="pe-2 mb-2">
      <div className="card rounded-4"
        style={{ width: 350, height: 167 }}>
        <div className="card-body text-left">
          <div className="p-2">
            <div className="card-title" style={{fontSize: '22px'}}>
              Open Ports
            </div>
            <div className="scrollbar-all-primary" style={{height: 70}}>          
              {setPortTable?.map((item, index) => (
                <span className="pe-1" style={{lineHeight: 2}} key={index}>
                  <span className="badge text-bg-secondary" style={{fontWeight: 400, fontSize: 13}}>
                    {item.port}
                  </span>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenPorts;