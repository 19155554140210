// api-config.js
const api_base_url_https = process.env.REACT_APP_API_BASE_URL_HTTPS;
const api_base_url_http = process.env.REACT_APP_API_BASE_URL_HTTP;
const api_base_ip = process.env.REACT_APP_API_BASE_IP;
const api_base_url_local = process.env.REACT_APP_API_BASE_URL_LOCAL;
const api_key = process.env.REACT_APP_API_KEY;

// Determine the API base URL based on the environment
let api_base_url;

// Use HTTPS URL in production, HTTP URL as fallback, and localhost in development
if (process.env.NODE_ENV === 'production') {
  api_base_url = api_base_ip || api_base_url_https || api_base_url_http;
} else {
  api_base_url = api_base_url_local || api_base_ip ||api_base_url_http || api_base_url_https;
}

// Ensure the base URL is defined
if (!api_base_url) {
  throw new Error('API base URL is not defined. Check your .env file.');
}

// Export the configuration
const apiConfig = { api_base_url, api_key };

export default apiConfig;